import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
    providedIn: 'root'
  })
export class AppService {
    base_url: string = 'http://mybackend.com/api/';
    questions_endpoint = 'questions';

    constructor(private http: HttpClient) {}

    getQuestions() {
        return this.http.get(this.base_url + this.questions_endpoint);
    } 
}