import { InMemoryDbService } from 'angular-in-memory-web-api';

export class FakeBackendService implements InMemoryDbService {
    constructor() { }

    createDb() {
        let questions = [
        {
            category: 'TV',
            question: 'Which girl group performed during the opening minutes of Channel 5’s launch in 1997 in the UK?',
            answer: 'The Spice Girls'
        },
        {
            category: 'TV',
            question: 'In what year did Coronation Street first air on ITV?',
            answer: 1960
        },
        {
            category: 'TV',
            question: 'How many episodes of Game of Thrones are there?',
            answer: 73
        },
        {
            category: 'TV',
            question: 'What is the highest-rated TV series on the IMDB Top 250 TV series list?',
            answer: 'Breaking Bad'
        },
        {
            category: 'TV',
            question: 'Which British TV show recorded the largest audience for a single episode with over 30 million viewers?',
            answer: 'Eastenders (on Christmas Day 1986)'
        },
        {
            category: 'TV',
            question: 'Where is Strictly Come Dancing filmed?',
            answer: 'Elstree Studios'
        },
        {
            category: 'TV',
            question: 'In what year did long-running children’s programme Blue Peter first broadcast in the UK?',
            answer: 1958
        },
        {
            category: 'TV',
            question: 'Who played Rodney Trotter in Only Fools and Horses?',
            answer: 'Nicholas Lyndhurst'
        },
        {
            category: 'TV',
            question: 'Which actor portrayed Inspector Morse?',
            answer: 'John Thaw'
        },
        {
            category: 'TV',
            question: 'Name the creators of Stranger Things',
            answer: 'The Duffer Brothers'
        },
        {
            category: 'TV',
            question: 'What’s the pub called in Channel soap Hollyoaks?',
            answer: 'The Dog in the Pond'
        },
        {
            category: 'TV',
            question: 'Who became the first female presenter of Question Time in 2019?',
            answer: 'Fiona Bruce'
        },
        {
            category: 'TV',
            question: 'For what is Nancy Cartwright best known?',
            answer: 'Being the voice of Bart Simpson in the Simpsons'
        },
        {
            category: 'TV',
            question: 'What is the name of Rene’s wife in ‘Allo ‘Allo?',
            answer: 'Edith'
        },
        {
            category: 'TV',
            question: 'Who does the voiceover on Love Island?',
            answer: 'Iain Stirling'
        },
        {
            category: 'TV',
            question: 'In which fictional town is Netflix show Stranger Things primarily set?',
            answer: 'Hawkins, Indiana'
        },
        {
            category: 'TV',
            question: 'What is the capital of Westeros in Game of Thrones?',
            answer: 'King’s Landing'
        },
        {
            category: 'TV',
            question: 'What is the name of the prison in the popular BBC sitcom Porridge starring Ronnie Barker?',
            answer: 'HMP Slade'
        },
        {
            category: 'TV',
            question: 'Which well-known Welsh village was the primary set for the 1960s TV show The Prisoner?',
            answer: 'Portmeirion'
        },
        {
            category: 'TV',
            question: 'Which TV theme begins with the words: “You know we belong together…”',
            answer: 'Home and Away'
        },
        {
            category: 'TV',
            question: 'Which critically acclaimed US TV drama was set around the fictional Sterling Cooper advertising agency in New York?',
            answer: 'Mad Men'
        },
        {
            category: 'TV',
            question: 'In which fictional town was the sitcom Dad’s Army set?',
            answer: 'Walmington-on-Sea'
        },
        {
            category: 'TV',
            question: 'Which comedian and actor plays Alan Partridge?',
            answer: 'Steve Coogan'
        },
        {
            category: 'TV',
            question: 'In which city do Ross and Rachel get married in Friends?',
            answer: 'Las Vegas (end of season 5)'
        },
        {
            category: 'TV',
            question: 'Which UK show is the world’s longest news and current affairs TV programme?',
            answer: 'Panorama (1953 to present day)'
        },
        {
            category: 'TV',
            question: 'The following have all presented which TV show? Bruce Forsyth, Larry Grayson, Jim Davidson and Mel & Sue (Mel Giedroyc and Sue Perkins)',
            answer: 'The Generation Game'
        },
        {
            category: 'TV',
            question: 'Who presents Dragons’ Den?',
            answer: 'Evan Davis'
        },
        {
            category: 'TV',
            question: 'In 1995, more than 22 million people tuned into a special BBC Panorama interview with Princess Diana. Which journalist conducted the interview?',
            answer: 'Martin Bashir'
        },
        {
            category: 'TV',
            question: 'What colour is Thunderbird 2 piloted by Virgil Tracy in Thunderbirds?',
            answer: 'Green'
        },
        {
            category: 'TV',
            question: 'What was the first full programme to be shown on Channel 4 on the afternoon of its launch in 1982?',
            answer: 'Countdown'
        },
        {
            category: 'TV',
            question: 'Who presented TV quiz Blockbusters between 1983 and 1995?',
            answer: 'Bob Holness'
        },
        {
            category: 'TV',
            question: 'Which member of The Beatles narrated the first series of Thomas the Tank Engine on TV?',
            answer: 'Ringo Starr'
        },
        {
            category: 'TV',
            question: 'Who plays Eleven in Stranger Things?',
            answer: 'Millie Bobby Brown'
        },
        {
            category: 'TV',
            question: 'Who co-created hit US sitcom Seinfeld with Jerry Seinfeld?',
            answer: 'Larry David'
        },
        {
            category: 'TV',
            question: 'Friends ended in May 2004 after how many seasons?',
            answer: 'Ten'
        },
        {
            category: 'TV',
            question: 'What is the main pub called in Peaky Blinders where the Shelby’s meet?',
            answer: 'The Garrison Tavern'
        },
        {
            category: 'TV',
            question: 'Long-running ITV police drama The Bill was set in which fictional suburb?',
            answer: 'Sun Hill'
        },
        {
            category: 'TV',
            question: 'Who played Alf Garnett in Till Death Us Do Part?',
            answer: 'Warren Mitchell'
        },
        {
            category: 'TV',
            question: 'Name the comedy duo who starred in anarchic hit Bottom',
            answer: 'Rik Mayall and Adrian Edmondson'
        },
        {
            category: 'TV',
            question: 'Who played Queen Elizabeth II in the first two seasons of The Crown?',
            answer: 'Claire Foy'
        },
        {
            category: 'TV',
            question: 'Which TV show features Miranda Hobbes and Samantha Jones?',
            answer: 'Sex and the City'
        },
        {
            category: 'TV',
            question: 'Ant and Dec first worked together on which children’s TV drama?',
            answer: 'Byker Grove'
        },
        {
            category: 'TV',
            question: 'Amy Poehler, Rob Lowe and Chris Pratt worked together on which US comedy series?',
            answer: 'Parks and Recreation'
        },
        {
            category: 'TV',
            question: 'Which entertainment show featured the phrase, ‘Tonight Matthew, I’m going to be…’?',
            answer: 'Stars In Their Eyes'
        },
        {
            category: 'TV',
            question: 'Which US comedy actress created the Netflix show, The Unbreakable Kimmy Schmidt?',
            answer: 'Tina Fey'
        },
        {
            category: 'TV',
            question: 'Which Cold Feet character died in a shock car crash?',
            answer: 'Rachel'
        },
        {
            category: 'TV',
            question: 'Name Casualty’s longest-serving character.',
            answer: 'Charlie Fairhead'
        },
        {
            category: 'TV',
            question: 'What is the name of Miranda Hart’s best friend in her hit sitcom?',
            answer: 'Stevie'
        },
        {
            category: 'TV',
            question: 'Which singing competition was the first to feature Simon Cowell as a judge?',
            answer: 'Pop Idol'
        },
        {
            category: 'TV',
            question: 'Which of these actors has not appeared in The Vicar of Dibley: Emilia Fox, Keeley Hawes, Peter Capaldi',
            answer: 'Emilia Fox'
        },
        {
            category: 'TV',
            question: 'In the TV show Hustle, who played lead character Mickey “Bricks” Stone?',
            answer: 'Adrian Lester'
        },
        {
            category: 'TV',
            question: 'Cop drama Life on Mars is named after a song by which musician?',
            answer: 'David Bowie'
        },
        {
            category: 'TV',
            question: 'In sci-fi series Black Mirror, which song regularly appears in different stories?',
            answer: 'Anyone Who Knows What Love Is (Will Understand) by Irma Thomas'
        },
        {
            category: 'TV',
            question: 'Who were the original presenters of Top Gear when it launched in 2002?',
            answer: 'Jeremy Clarkson, Richard Hammond and Jason Dawe'
        },
        {
            category: 'TV',
            question: 'In what year was the Independent Television network, later known as ITV, launched?',
            answer: 1955
        },
        {
            category: 'Film',
            question: 'What year was the first Toy Story film released in cinemas?',
            answer: 1995
        },
        {
            category: 'Film',
            question: 'Who directed Titanic, Avatar and The Terminator?',
            answer: 'James Cameron'
        },
        {
            category: 'Film',
            question: 'Which three films make up what is known as the Three Flavours Cornetto Trilogy?',
            answer: 'Shaun of the Dead, Hot Fuzz, The World’s End'
        },
        {
            category: 'Film',
            question: 'Who directed Parasite – the first foreign-language film to win the Academy Award for Best Picture?',
            answer: 'Bong Joon-ho'
        },
        {
            category: 'Film',
            question: 'Which Oscar-winning actress is the voice of Helen Parr (Elastigirl) in The Incredibles?',
            answer: 'Holly Hunter'
        },
        {
            category: 'Film',
            question: 'Name the 2015 film spinoff to the Rocky series starring Michael B. Jordan.',
            answer: 'Creed'
        },
        {
            category: 'Film',
            question: 'Meryl Streep won a Best Actress BAFTA for which 2011 political drama?',
            answer: 'The Iron Lady'
        },
        {
            category: 'Film',
            question: 'BD Wong voices Captain Li Shang in the animated musical Mulan, but which 70’s teen heartthrob provided the character’s singing voice?',
            answer: 'Donny Osmond'
        },
        {
            category: 'Film',
            question: 'Which actor broke two toes whilst filming The Lord of the Rings: The Two Towers?',
            answer: 'Viggo Mortensen, whilst kicking a helmet.'
        },
        {
            category: 'Film',
            question: 'Name the three movies in which Meg Ryan and Tom Hanks have starred together.',
            answer: 'Joe Versus the Volcano (1990), Sleepless in Seattle (1993) and You’ve Got Mail (1998)'
        },
        {
            category: 'Film',
            question: 'What is the highest-grossing box office film of all time?',
            answer: 'Avengers: Endgame'
        },
        {
            category: 'Film',
            question: 'Russell Crowe turned down the role of which character in Peter Jackson’s Lord of the Rings trilogy?',
            answer: 'Aragorn'
        },
        {
            category: 'Film',
            question: 'How many films have Kate Winslet and Leonardo DiCaprio starred in together?',
            answer: 'Two (Titanic, Revolutionary Road)'
        },
        {
            category: 'Film',
            question: 'Name the film which boasts the famous line: “You can’t handle the truth!”',
            answer: 'A Few Good Men'
        },
        {
            category: 'Film',
            question: 'What is the first word spoken in Star Wars: The Empire Strikes Back?',
            answer: 'Echo'
        },
        {
            category: 'Film',
            question: 'Who has won the most Oscars for acting in the history of the Academy Awards?',
            answer: 'Katherine Hepburn – 4, Morning Glory (1933) Guess Who’s Coming to Dinner (1967) The Lion in Winter (1968) On Golden Pond (1981)'
        },
        {
            category: 'Film',
            question: 'Which 1995 submarine drama featured uncredited additional dialogue courtesy of Quentin Tarantino?',
            answer: 'Crimson Tide'
        },
        {
            category: 'Film',
            question: 'Who played the lead role in the 2001 film Lara Croft: Tomb Raider?',
            answer: 'Angelina Jolie'
        },
        {
            category: 'Film',
            question: 'Who was the director of 1996 action thriller The Rock?',
            answer: 'Michael Bay'
        },
        {
            category: 'Film',
            question: 'What is the name of Tom Hanks’ directorial debut which charts the rise and fall of a one-hit-wonder band in the 1960s?',
            answer: 'That Thing You Do'
        },
        {
            category: 'Film',
            question: 'In 1994 romcom Four Weddings and a Funeral, whose funeral does the group attend?',
            answer: 'The funeral of Gareth (played by Simon Callow)'
        },
        {
            category: 'Film',
            question: 'Who plays the titular role in 2018 superhero film Black Panther?',
            answer: 'Chadwick Boseman'
        },
        {
            category: 'Film',
            question: 'Which US comedian wrote and directed Get Out and Us?',
            answer: 'Jordan Peele'
        },
        {
            category: 'Film',
            question: 'What is the name of Wes Anderson’s upcoming comedy-drama starring Benicio del Toro, Tilda Swinton and Timothée Chalamet?',
            answer: 'The French Dispatch'
        },
        {
            category: 'Film',
            question: 'What is the name of the second James Bond film?',
            answer: 'From Russia With Love'
        },
        {
            category: 'Film',
            question: 'What is the name of the spell used by Ron and Hermoine in Harry Potter and the Philosopher’s Stone to make their feathers fly?',
            answer: 'Wingardium Leviosa'
        },
        {
            category: 'Film',
            question: 'What happens to Chihiro’s parents in the 2001 Japanese film Spirited Away?',
            answer: 'They are transformed into pigs'
        },
        {
            category: 'Film',
            question: 'In ‘90s romcom Clueless, who plays Josh Lucas – Cher Horowitz’s ex-step-brother?',
            answer: 'Paul Rudd'
        },
        {
            category: 'Film',
            question: 'How many Academy Awards has Leonardo DiCaprio won?',
            answer: 'Despite being nominated 6 times, he has only won once – in 2016 for The Revenant'
        },
        {
            category: 'Film',
            question: 'Which actor got his big break playing a lonely schoolboy in About A Boy?',
            answer: 'Nicholas Hoult'
        },
        {
            category: 'Film',
            question: 'For which film did Sandra Bullock win her Oscar?',
            answer: 'The Blind Side'
        },
        {
            category: 'Film',
            question: 'Cool Runnings is the story of which country entering a bobsleigh team into the Winter Olympics?',
            answer: 'Jamaica'
        },
        {
            category: 'Film',
            question: '‘Frankly my dear, I don’t give a damn’ is an iconic line from which classic film?',
            answer: 'Gone with the Wind'
        },
        {
            category: 'Film',
            question: 'Emma Thompson made the nation cry in Love Actually – she thought her husband had bought her a necklace, but instead she received an album by which artist?',
            answer: 'Joni Mitchell'
        },
        {
            category: 'Film',
            question: 'Who replaced Richard Harris as Dumbledore in the Harry Potter films?',
            answer: 'Michael Gambon'
        },
        {
            category: 'Film',
            question: 'What does Tom Hanks compare life to in Forest Gump?',
            answer: 'A box of chocolates'
        },
        {
            category: 'Film',
            question: 'Which movie features an iconic dance scene between Uma Thurman and John Travolta?',
            answer: 'Pulp Fiction'
        },
        {
            category: 'Film',
            question: 'In which Austin Powers film does Beyoncé make her movie debut?',
            answer: 'Goldmember'
        },
        {
            category: 'Film',
            question: 'Who does Will Ferrell play in Anchorman?',
            answer: 'Ron Burgundy'
        },
        {
            category: 'Film',
            question: 'In Indiana Jones and the Temple of Doom, which Star Wars character gets a namecheck?',
            answer: 'Obi-Wan Kenobi – the club at the start of the film is called Club Obi-Wan'
        },
        {
            category: 'Film',
            question: 'What is the name of Humphrey Bogart’s character in Casablanca?',
            answer: 'Rick Blaine'
        },
        {
            category: 'Film',
            question: 'Who is Alan Smithee?',
            answer: 'Alan Smithee is an official pseudonym used by directors when they want to disown a film – in other words, Alan Smithee is credited as director'
        },
        {
            category: 'Film',
            question: 'What was Orlando Bloom’s first film role?',
            answer: 'He played a young prostitute in Oscar Wilde biopic Wilde'
        },
        {
            category: 'Film',
            question: 'Which Shakespearean actor directed the first Thor movie?',
            answer: 'Sir Kenneth Branagh'
        },
        {
            category: 'Sport',
            question: 'How many points did Liverpool record in the 2019/20 Premier League season before football was postponed?',
            answer: 82
        },
        {
            category: 'Sport',
            question: 'Name the only football team to sack Sir Alex Ferguson as a manager.',
            answer: 'St Mirren'
        },
        {
            category: 'Sport',
            question: 'How many times has Rafael Nadal won the French Open?',
            answer: 12
        },
        {
            category: 'Sport',
            question: 'Which team has won more trophies since the first Ryder Cup golf tournament in 1927 – USA or Great Britain/Europe?',
            answer: 'USA (26 – compared to Britain and Europe’s combined total of 14)'
        },
        {
            category: 'Sport',
            question: 'Which international team currently sits top of the FIFA Men’s World Rankings? (April 2020)',
            answer: 'Belgium'
        },
        {
            category: 'Sport',
            question: 'In which year did American Football star Tom Brady win his first Super Bowl with the New England Patriots?',
            answer: 2002
        },
        {
            category: 'Sport',
            question: 'Who is the only player to have scored in the Premier League, Championship, League 1, League 2, Conference, FA Cup, League Cup, Football League Trophy, FA Trophy, Champions League, Europa League, Scottish Premier League, Scottish Cup and Scottish League Cup?',
            answer: 'Gary Hooper'
        },
        {
            category: 'Sport',
            question: 'Who is the last non-British, non-German Formula 1 driver to win the World Driver’s title?',
            answer: 'Kimi Raikkonen (2007)'
        },
        {
            category: 'Sport',
            question: 'Eoin Morgan set the record for number of sixes in a one-day international cricket match against Afghanistan at the 2019 Cricket World Cup. How many sixes did he hit?',
            answer: 17
        },
        {
            category: 'Sport',
            question: 'Which WWE superstar did Tyson Fury wrestle in 2019?',
            answer: 'Braun Strowman'
        },
        {
            category: 'Sport',
            question: 'Who is the top scorer for the England Women’s national football team?',
            answer: 'Kelly Smith'
        },
        {
            category: 'Sport',
            question: 'In which sport would competitors use a piece of equipment known as a foil?',
            answer: 'Fencing'
        },
        {
            category: 'Sport',
            question: 'Frankie Dettori won seven races out of seven races at which UK horse racing course in 1995?',
            answer: 'Ascot'
        },
        {
            category: 'Sport',
            question: 'In which sport do teams compete to win the Stanley Cup?',
            answer: 'Ice hockey (NHL)'
        },
        {
            category: 'Sport',
            question: 'What is Usain Bolt’s blistering 100m world record time?',
            answer: '9.58 seconds'
        },
        {
            category: 'Sport',
            question: 'Which famous football manager once said: “I wouldn’t say I was the best manager in the business. But I was in the top one”?',
            answer: 'Brian Clough'
        },
        {
            category: 'Sport',
            question: 'Did Team GB win more medals in total at London 2012 or Rio 2016?',
            answer: 'Rio 2016 (67 – compared to 65 at London 2012)'
        },
        {
            category: 'Sport',
            question: 'The first FIFA World Cup was held in which year? (Bonus point for the host nation)',
            answer: '1930 (Uruguay)'
        },
        {
            category: 'Sport',
            question: 'England won the 2003 Rugby World Cup thanks to an iconic drop goal from Jonny Wilkinson. How many points did England score in the match?',
            answer: 20
        },
        {
            category: 'Sport',
            question: 'How many MMA fights has UFC star Conor McGregor lost during his career?',
            answer: 'Four'
        },
        {
            category: 'Sport',
            question: 'Coco Gauff made headlines at Wimbledon last year when she beat Venus Williams. How old was Gauff?',
            answer: 15
        },
        {
            category: 'Sport',
            question: 'Which English Football League team was given the nickname The Gas?',
            answer: 'Bristol Rovers'
        },
        {
            category: 'Sport',
            question: 'What is Muhammad Ali’s real name?',
            answer: 'Cassius Clay'
        },
        {
            category: 'Sport',
            question: 'What is the biggest capacity cricket ground in England?',
            answer: 'Lord’s'
        },
        {
            category: 'Sport',
            question: 'Complete the name of the current NBA champions: Toronto _____.',
            answer: 'Raptors'
        },
        {
            category: 'Sport',
            question: 'Did Cristiano Ronaldo score more goals or play more games for Real Madrid in all competitions?',
            answer: 'More goals'
        },
        {
            category: 'Sport',
            question: 'Which course is The Masters golf tournament held?',
            answer: 'Augusta National Golf Club'
        },
        {
            category: 'Sport',
            question: 'Name the three players to win the 2018/19 Premier League Golden Boot.',
            answer: 'Mohamed Salah, Sadio Mane, Pierre-Emerick Aubameyang'
        },
        {
            category: 'Sport',
            question: 'In rugby union, who is England Men’s all-time top try scorer?',
            answer: 'Rory Underwood'
        },
        {
            category: 'Sport',
            question: 'How many balls are in total are there on the table at the start of a game of snooker?',
            answer: 22
        },
        {
            category: 'Sport',
            question: 'At which Olympics did Kelly Holmes win two gold medals?',
            answer: 'Athens (2004)'
        },
        {
            category: 'Sport',
            question: 'In which sport is 180 deemed a perfect score?',
            answer: 'Darts'
        },
        {
            category: 'Sport',
            question: 'Which of these is not a real sport: a) footgolf b) hand rounders c) chess boxing',
            answer: 'b) hand rounders'
        },
        {
            category: 'Sport',
            question: 'In which sport do competitors refer to ‘catching a crab’?',
            answer: 'Rowing'
        },
        {
            category: 'Sport',
            question: 'Which sport, played on ice, did the nation suddenly become obsessed with when the GB women’s team won gold at the 2002 Winter Olympics?',
            answer: 'Curling'
        },
        {
            category: 'Sport',
            question: 'Which sport involves tucks and pikes?',
            answer: 'Diving'
        },
        {
            category: 'Sport',
            question: 'Who has won more grand slam tennis titles: Serena Williams or Roger Federer?',
            answer: 'Serena Williams'
        },
        {
            category: 'Sport',
            question: 'Jade Jones took home gold for Team GB in the 2012 and 2016 Olympics. Which sport does she compete in?',
            answer: 'Taekwondo'
        },
        {
            category: 'Sport',
            question: 'Which England footballer was famously never given a yellow card?',
            answer: 'Gary Lineker'
        },
        {
            category: 'Sport',
            question: 'The Los Angeles Lakers and New York Knicks play which sport?',
            answer: 'Basketball'
        },
        {
            category: 'Sport',
            question: 'In cricket, what is the The Duckworth-Lewis-Stern method?',
            answer: 'A mathematical formulation designed to calculate the target score for the team batting second in a limited overs cricket match interrupted by weather or other circumstances / calculating the score required to win in a rain affected game of cricket/ which team wins, should the game be affected by rain'
        },
        {
            category: 'Sport',
            question: 'Which 2012 Olympic event recorded record crowd noises of 114 decibels?',
            answer: 'Irish boxer Katie Taylor’s victory over British contender Natasha Jonas in the semi-finals'
        },
        {
            category: 'Sport',
            question: 'Before Andy Murray, who was the last British tennis player to win a men’s singles Grand Slam tournament?',
            answer: 'Fred Perry'
        },
        {
            category: 'Sport',
            question: 'After retiring from professional cycling, what other sport did Bradley Wiggins briefly attempt to make a career in?',
            answer: 'Rowing'
        },
        {
            category: 'Sport',
            question: 'Where is the next Olympic Games set to take place?',
            answer: 'Tokyo'
        },
        {
            category: 'Music',
            question: 'In What year did the Nirvana frontman Kurt Cobain die? (a bonus point for the month)',
            answer: '1994 (April)'
        },
        {
            category: 'Music',
            question: 'One Direction came third in the X Factor in 2010 and went on to become one of the biggest boy bands of all time – name the five original members… (a point for each)',
            answer: 'Harry Styles, Nial Horan, Liam Payne, Louis Tomlinson, Zayn Malik'
        },
        {
            category: 'Music',
            question: 'The following is a line from which song? “I’m never gonna dance again, Guilty feet have got no rhythm”',
            answer: 'Careless Whisper by George Michael'
        },
        {
            category: 'Music',
            question: 'What is the name of The Beatles’ first album?',
            answer: 'Please Please Me'
        },
        {
            category: 'Music',
            question: 'At which Hertfordshire manor house did Oasis play to 250,000 people across a weekend in 1996?',
            answer: 'Knebworth'
        },
        {
            category: 'Music',
            question: 'Which artist had a number one hit with Sledgehammer?',
            answer: 'Peter Gabriel'
        },
        {
            category: 'Music',
            question: 'What does the ‘O’ stand for in the band name ELO?',
            answer: 'Orchestra – (Electric Light Orchestra)'
        },
        {
            category: 'Music',
            question: 'Which mathematical symbol was the title of Ed Sheeran’s first album in 2011?',
            answer: '=+'
        },
        {
            category: 'Music',
            question: 'Complete the lyric from the well know Rihanna hit. “We found love in a…”',
            answer: 'Hopeless Place (We Found Love is one of the fastest selling singles of all time)'
        },
        {
            category: 'Music',
            question: 'According to punk rockers Green Day, at the end of which month would they like to be woken up?',
            answer: 'September (Wake Me Up When September Ends)'
        },
        {
            category: 'Music',
            question: 'What was Britney Spears’ first single called?',
            answer: 'Baby One More Time'
        },
        {
            category: 'Music',
            question: 'Reginald Kenneth Dwight is better known by what name?',
            answer: 'Elton John'
        },
        {
            category: 'Music',
            question: 'What was the name of Amy Winehouse’s second and final studio album released in 2006 and reached number one in multiple countries across the globe?',
            answer: 'Back to Black'
        },
        {
            category: 'Music',
            question: 'Which band had a huge international hit album in 2002 with the record “A Rush of Blood to the Head”?',
            answer: 'Coldplay'
        },
        {
            category: 'Music',
            question: 'Knights in White Satin was a 1967 hit for which band?',
            answer: 'The Moody Blues'
        },
        {
            category: 'Music',
            question: 'Which band sang The Final Countdown?',
            answer: 'Europe'
        },
        {
            category: 'Music',
            question: 'What was David Bowie’s real surname?',
            answer: 'Jones. His real name was David Robert Jones.'
        },
        {
            category: 'Music',
            question: 'How many members are there in South Korean mega boyband BTS?',
            answer: 7
        },
        {
            category: 'Music',
            question: 'Which talent show judge managed Westlife?',
            answer: 'Louis Walsh'
        },
        {
            category: 'Music',
            question: 'Who sang the 1965 James Bond theme, Thunderball?',
            answer: 'Tom Jones'
        },
        {
            category: 'Music',
            question: 'Roger Taylor is the drummer in which band?',
            answer: 'Queen'
        },
        {
            category: 'Music',
            question: 'In which year did the Spice Girls release Wannabe?',
            answer: 1996
        },
        {
            category: 'Music',
            question: 'Spandau Ballet features which former EastEnders actor in its line-up?',
            answer: 'Martin Kemp'
        },
        {
            category: 'Music',
            question: 'Which song begins with the line, ‘I thought love was only true in fairy tales’?',
            answer: 'I’m A Believer by The Monkees'
        },
        {
            category: 'Music',
            question: 'Linger and Dreams were hits for which Irish group?',
            answer: 'The Cranberries'
        },
        {
            category: 'Music',
            question: 'Which Bob Dylan song did Adele include on her first album?',
            answer: 'Make You Feel My Love'
        },
        {
            category: 'Music',
            question: 'Which novelty act had a Christmas number one in 2000 with, ‘Can We Fix It?’',
            answer: 'Bob the Builder'
        },
        {
            category: 'Music',
            question: 'Which 80s group, featuring Phil Collins, recently announced a reunion?',
            answer: 'Genesis'
        },
        {
            category: 'Music',
            question: 'Over the years, how many Sugababes have there been?',
            answer: 'Six: Keisha, Mutya, Siobhan, Heidi, Amelle and Jade'
        },
        {
            category: 'Music',
            question: 'Which girl group had hits including Pure Shores and Never Ever?',
            answer: 'All Saints'
        },
        {
            category: 'Music',
            question: 'What’s the name of the 1957 musical prison drama starring Elvis Presley?',
            answer: 'Jailhouse Rock'
        },
        {
            category: 'Music',
            question: 'Who became the lead singer of the 90s band Cast, after serving as the bassist for the La’s?',
            answer: 'John Power'
        },
        {
            category: 'Music',
            question: 'What was the name of the original drummer for The Beatles?',
            answer: 'Pete Best'
        },
        {
            category: 'Music',
            question: 'In what decade was American jazz trumpeter Miles Davis born?',
            answer: '1920s'
        },
        {
            category: 'Music',
            question: 'Which artist headlined Friday night on the Pyramid Stage at Glastonbury Festival 2019?',
            answer: 'Stormzy'
        },
        {
            category: 'Music',
            question: 'What was the name of Madonna’s first studio album, released in 1983?',
            answer: 'Madonna'
        },
        {
            category: 'Music',
            question: 'Rockstar David Howell Evans is better known by what name?',
            answer: 'The Edge (the guitarist in U2)'
        },
        {
            category: 'Music',
            question: 'Complete the first line of the Bill Withers hit: “Ain’t no sunshine …”',
            answer: 'When she’s gone'
        },
        {
            category: 'Music',
            question: 'Will.i.am is best known for performing with which hip hop group?',
            answer: 'The Black Eyed Peas'
        },
        {
            category: 'Music',
            question: 'What English county do Supergrass and Radiohead have in common?',
            answer: 'Oxfordshire (it’s where they were both founded)'
        },
        {
            category: 'Music',
            question: 'Which band released a tie-in single for the live-action 2004 Thunderbirds movie?',
            answer: 'Busted (with the track Thunderbirds Are Go, also known as Thunderbirds/3AM)'
        },
        {
            category: 'Music',
            question: 'Björn Again is a tribute band for which world-famous pop group?',
            answer: 'ABBA'
        },
        {
            category: 'Music',
            question: 'Which famed British musician played a wizard singer in Harry Potter and the Goblet of Fire?',
            answer: 'Pulp’s Jarvis Cocker.'
        },
        {
            category: 'Music',
            question: 'In what year were the hit songs Uptown Funk, Bad Blood, Chandelier, Fancy, Rather Be and Anaconda released?',
            answer: 2014
        },
        {
            category: 'Music',
            question: 'What was the name of Johnny Cash’s backing band throughout his career?',
            answer: 'The Tennessee Three (Accept Tennessee Two)'
        },
        {
            category: 'Food and Drink',
            question: 'What is James Bond’s favourite tipple?',
            answer: 'Vodka Martini – shaken not stirred'
        },
        {
            category: 'Food and Drink',
            question: 'What is the most consumed manufactured drink in the world?',
            answer: 'Tea'
        },
        {
            category: 'Food and Drink',
            question: 'What type of pasta has a name meaning “little worms”?',
            answer: 'Vermicelli'
        },
        {
            category: 'Food and Drink',
            question: 'What type of pastry are profiteroles made out of?',
            answer: 'Choux'
        },
        {
            category: 'Food and Drink',
            question: 'From which type of flower does a vanilla pod come?',
            answer: 'Orchid'
        },
        {
            category: 'Food and Drink',
            question: 'Which nuts are used in marzipan?',
            answer: 'Almonds'
        },
        {
            category: 'Food and Drink',
            question: 'Which bakery launched a sell-out vegan sausage roll in January 2019?',
            answer: 'Greggs'
        },
        {
            category: 'Food and Drink',
            question: 'What type of food is Pumpernickel?',
            answer: 'Bread'
        },
        {
            category: 'Food and Drink',
            question: 'What is the best selling flavour of soup in the UK?',
            answer: 'Tomato'
        },
        {
            category: 'Food and Drink',
            question: 'Calamari is a dish made from which animal?',
            answer: 'Squid'
        },
        {
            category: 'Food and Drink',
            question: 'Which foul-mouthed chef hosted the Kitchen Nightmares series?',
            answer: 'Gordon Ramsay'
        },
        {
            category: 'Food and Drink',
            question: 'Which bread ingredient causes it to rise?',
            answer: 'Yeast'
        },
        {
            category: 'Food and Drink',
            question: 'Aduki, borlotti and cannellini are types of what?',
            answer: 'Beans'
        },
        {
            category: 'Food and Drink',
            question: 'What is the most expensive spice in the world by weight?',
            answer: 'Saffron'
        },
        {
            category: 'Food and Drink',
            question: 'What spirit is mixed with ginger beer in a Moscow mule?',
            answer: 'Vodka'
        },
        {
            category: 'Food and Drink',
            question: 'What year did the first McDonald’s store open?',
            answer: 1940
        },
        {
            category: 'Food and Drink',
            question: 'What are the real names of the Hairy Bikers?',
            answer: 'Dave Myers and Si King'
        },
        {
            category: 'Food and Drink',
            question: 'Which pungent plant has its own festival held on the Isle of Wight every year?',
            answer: 'Garlic'
        },
        {
            category: 'Food and Drink',
            question: 'How many calories does a glass of water contain?',
            answer: 'Zero'
        },
        {
            category: 'Food and Drink',
            question: 'Who is replacing Sandi Toksvig as host of The Great British Bake Off for the 2020 series?',
            answer: 'Matt Lucas'
        },
        {
            category: 'Food and Drink',
            question: 'Grenadine is obtained from which fruit?',
            answer: 'Pomegranate'
        },
        {
            category: 'Food and Drink',
            question: 'Gluten is found in which cereal grain?',
            answer: 'Wheat'
        },
        {
            category: 'Food and Drink',
            question: 'Which fast-food franchise has the largest number of restaurants in the world?',
            answer: 'Subway'
        },
        {
            category: 'Food and Drink',
            question: 'What French city does bouillabaisse originate from?',
            answer: 'Marseille'
        },
        {
            category: 'Food and Drink',
            question: 'What type of wheat is used to make pasta?',
            answer: 'Durum'
        },
        {
            category: 'Food and Drink',
            question: 'What is the British name for a zucchini?',
            answer: 'Courgette'
        },
        {
            category: 'Food and Drink',
            question: 'In which country will you find wine-growing region the Yarra Valley?',
            answer: 'Australia'
        },
        {
            category: 'Food and Drink',
            question: 'How many bottles of champagne are there in a magnum?',
            answer: 'Two'
        },
        {
            category: 'Food and Drink',
            question: 'Deer meat is known by what name?',
            answer: 'Venison'
        },
        {
            category: 'Food and Drink',
            question: 'Bacardi is what type of spirit?',
            answer: 'White Rum'
        },
        {
            category: 'Geography',
            question: 'What country has the longest coastline in the world?',
            answer: 'Canada'
        },
        {
            category: 'Geography',
            question: 'What country is Beirut the capital of?',
            answer: 'Lebanon'
        },
        {
            category: 'Geography',
            question: 'What country is the newest in the world to be recognised by the UN?',
            answer: 'South Sudan (2011)'
        },
        {
            category: 'Geography',
            question: 'In which English county is the town of Stevenage?',
            answer: 'Hertfordshire'
        },
        {
            category: 'Geography',
            question: 'What is the oldest recorded town in the UK?',
            answer: 'Colchester'
        },
        {
            category: 'Geography',
            question: 'How many US states begin with the letter A?',
            answer: 'Four – Alabama, Alaska, Arizona, Arkansas'
        },
        {
            category: 'Geography',
            question: 'What is the name of the largest river to flow through Paris?',
            answer: 'The Seine'
        },
        {
            category: 'Geography',
            question: 'What did Ceylon change its name to in 1972?',
            answer: 'Sri Lanka'
        },
        {
            category: 'Geography',
            question: 'What is the capital of Chile?',
            answer: 'Santiago'
        },
        {
            category: 'Geography',
            question: 'What is the highest mountain in Britain?',
            answer: 'Ben Nevis'
        },
        {
            category: 'Geography',
            question: 'The world’s first national park was established in 1862 in which country? A bonus point for the name of the park…',
            answer: 'USA, Yellowstone'
        },
        {
            category: 'Geography',
            question: 'What is the capital of Poland?',
            answer: 'Warsaw'
        },
        {
            category: 'Geography',
            question: 'Mount Vesuvius casts a shadow over which modern Italian city?',
            answer: 'Naples'
        },
        {
            category: 'Geography',
            question: 'What is the most recent state to be added to the USA?',
            answer: 'Hawaii (August 1959)'
        },
        {
            category: 'Geography',
            question: 'What is the currency of Sweden?',
            answer: 'Swedish Krona'
        },
        {
            category: 'Geography',
            question: 'What is the name of the largest airport in Amsterdam?',
            answer: 'Schiphol'
        },
        {
            category: 'Geography',
            question: 'What is the capital of New Zealand?',
            answer: 'Wellington'
        },
        {
            category: 'Geography',
            question: 'How many states are there in Australia?',
            answer: 'Six – New South Wales, Victoria, Queensland, Western Australia, Tasmania, South Australia'
        },
        {
            category: 'Geography',
            question: 'What African country has the largest population?',
            answer: 'Nigeria (190 million)'
        },
        {
            category: 'Geography',
            question: 'The southern tip of South America has what name?',
            answer: 'Cape Horn'
        },
        {
            category: 'Geography',
            question: 'What is the longest river in the UK?',
            answer: 'River Severn'
        },
        {
            category: 'Geography',
            question: 'Which Central American country has a name which translates to English as “The Saviour”?',
            answer: 'El Salvador'
        },
        {
            category: 'Geography',
            question: 'In which city would you find La Sagrada Familia?',
            answer: 'Barcelona'
        },
        {
            category: 'Geography',
            question: 'What is the largest desert in the world?',
            answer: 'Antarctica'
        },
        {
            category: 'Geography',
            question: 'Which line on a London Underground map is coloured brown?',
            answer: 'Bakerloo'
        },
        {
            category: 'Geography',
            question: 'In which US state would you find Fort Knox?',
            answer: 'Kentucky'
        },
        {
            category: 'Geography',
            question: 'The Strait of Gibraltar separates the Iberian Peninsular from which African country?',
            answer: 'Morocco'
        },
        {
            category: 'Geography',
            question: 'What is the smallest country in the world?',
            answer: 'Vatican City'
        },
        {
            category: 'Geography',
            question: 'Alberta is a province of which country?',
            answer: 'Canada'
        },
        {
            category: 'Geography',
            question: 'How many countries still have the shilling as currency?',
            answer: 'Four – Kenya, Uganda, Tanzania and Somalia'
        },
        {
            category: 'Geography',
            question: 'Which is the only vowel not used as the first letter in a US State?',
            answer: 'E'
        },
        {
            category: 'Geography',
            question: 'On the London Tube network, which is the only station to begin with the letter i?',
            answer: 'Ickenham'
        },
        {
            category: 'Geography',
            question: 'Mount Vesuvius overlooks which present-day Italian city?',
            answer: 'Naples'
        },
        {
            category: 'Geography',
            question: 'In what country would you find Lake Bled?',
            answer: 'Slovenia'
        },
        {
            category: 'Geography',
            question: 'Dracula famously lived in the historical region of Transylvania – but in what country would you now find his castle?',
            answer: 'Romania'
        },
        {
            category: 'Easy',
            question: 'Who usually presents the 10pm news on BBC One?',
            answer: 'Huw Edwards'
        },
        {
            category: 'Easy',
            question: 'What is the capital of Wales?',
            answer: 'Cardiff'
        },
        {
            category: 'Easy',
            question: 'What number is a baker’s dozen?',
            answer: 13
        },
        {
            category: 'Easy',
            question: 'How many times have England won the men’s football World Cup?',
            answer: 'Once (in 1966)'
        },
        {
            category: 'Easy',
            question: 'What is the currency of Portugal?',
            answer: 'Euro'
        },
        {
            category: 'Easy',
            question: 'A marathon is how many miles?',
            answer: '26 (actually it’s 26.2, but we’ll accept 26)'
        },
        {
            category: 'Easy',
            question: 'The Academy of Motion Picture Arts and Sciences are best known for which annual award ceremony?',
            answer: 'The Oscars'
        },
        {
            category: 'Easy',
            question: 'Where would you wear brogues?',
            answer: 'On your feet, they’re shoes'
        },
        {
            category: 'Easy',
            question: 'In what year was the Battle of Hastings?',
            answer: 1066
        },
        {
            category: 'Easy',
            question: 'Which dutch artist famously painted sunflowers?',
            answer: 'Vincent Van Gogh'
        },
        {
            category: 'Easy',
            question: 'On what birthday do British citizens first receive a telegram from the Queen?',
            answer: '100th'
        },
        {
            category: 'Easy',
            question: 'EastEnders is set in which fictional London borough?',
            answer: 'Walford'
        },
        {
            category: 'Easy',
            question: 'What does “www” stand for in the context of an internet address?',
            answer: 'World Wide Web'
        },
        {
            category: 'Easy',
            question: 'Which of these precious metals is more expensive per ounce, gold or silver?',
            answer: 'Gold'
        },
        {
            category: 'Easy',
            question: 'Which is bigger, A4 paper or A5 paper?',
            answer: 'A4'
        },
        {
            category: 'Easy',
            question: 'Who plays Del Boy Trotter in Only Fools and Horses?',
            answer: 'David Jason'
        },
        {
            category: 'Easy',
            question: 'What is the capital of the USA?',
            answer: 'Washington DC'
        },
        {
            category: 'Easy',
            question: 'Levi Strauss is best known for manufacturing what kind of clothing?',
            answer: 'Jeans (and denim goods)'
        },
        {
            category: 'Easy',
            question: 'Name the 1975 Steven Spielberg film about a shark',
            answer: 'Jaws'
        },
        {
            category: 'Easy',
            question: 'What does the television channel’s name ITV stand for?',
            answer: 'Independent Television'
        },
        {
            category: 'Easy',
            question: 'Which English King beheaded two of his wives?',
            answer: 'Henry VIII'
        },
        {
            category: 'Easy',
            question: 'H20 is the chemical formula for what?',
            answer: 'Water'
        },
        {
            category: 'Easy',
            question: 'Which gas is used to fill balloons?',
            answer: 'Helium'
        },
        {
            category: 'Easy',
            question: 'In which fictional city does Batman reside?',
            answer: 'Gotham'
        },
        {
            category: 'Easy',
            question: 'In the traditional rhyme, how many mice were blind?',
            answer: 'Three'
        },
        {
            category: 'Easy',
            question: 'What is the number 5 in roman numerals?',
            answer: 'V'
        },
        {
            category: 'Easy',
            question: 'What colour is the bullseye of a dartboard?',
            answer: 'Red'
        },
        {
            category: 'Easy',
            question: 'What side of a ship is starboard?',
            answer: 'Right'
        },
        {
            category: 'Easy',
            question: 'Ian McKellen played what wizard in The Lord of the Rings?',
            answer: 'Gandalf'
        },
        {
            category: 'Easy',
            question: 'What year did World War One begin?',
            answer: 1914
        },
        {
            category: 'Sitcom',
            question: 'What was the original title for Dad’s Army?',
            answer: 'The Fighting Tigers'
        },
        {
            category: 'Sitcom',
            question: 'Complete this Only Fools and Horses catchphrase: “Next time this year we’ll be….”',
            answer: 'Millionaires'
        },
        {
            category: 'Sitcom',
            question: 'What is the name of Blackadder’s faithful but dim-witted servant?',
            answer: 'Baldrick'
        },
        {
            category: 'Sitcom',
            question: 'What is the name of Jim’s original dog in Friday Night Dinner?',
            answer: 'Wilson'
        },
        {
            category: 'Sitcom',
            question: 'Who was the first male character to get married in The Big Bang Theory?',
            answer: 'Howard'
        },
        {
            category: 'Sitcom',
            question: 'Who did Wendy Richard play in Are You Being Served?',
            answer: 'Miss Brahms'
        },
        {
            category: 'Sitcom',
            question: 'In which town is The Office UK set?',
            answer: 'Slough'
        },
        {
            category: 'Sitcom',
            question: 'What is the name of the final episode of M*A*S*H, which drew 125million viewers in the US?',
            answer: 'Goodbye, Farewell and Amen'
        },
        {
            category: 'Sitcom',
            question: 'Anthony, Barbara, Dave, Denise and Jim are members of which famous TV family?',
            answer: 'The Royle Family'
        },
        {
            category: 'Sitcom',
            question: 'Who plays Gary’s flatmate in the first series of Men Behaving Badly?',
            answer: 'Harry Enfield'
        },
        {
            category: 'Sitcom',
            question: 'True or false: Josh Radnor does the voice-over on How I Met Your Mother',
            answer: 'False – it’s actually Bob Saget'
        },
        {
            category: 'Sitcom',
            question: 'Mila Kunis had her first kiss with future husband Ashton Kutcher on which nostalgic sitcom?',
            answer: 'That ’70s Show'
        },
        {
            category: 'Sitcom',
            question: 'In Rising Damp Rigsby would always picture what animal when thinking about his mother-in-law?',
            answer: 'Hippopotamus'
        },
        {
            category: 'Sitcom',
            question: 'In what county was Fawlty Towers set, which was also the location of the real-life hotel that inspired the show?',
            answer: 'Devon'
        },
        {
            category: 'Sitcom',
            question: 'In what year did classic Ronnie Barker sitcom Porridge begin?',
            answer: 1974
        },
        {
            category: 'Sitcom',
            question: 'Lister, Rimmer and Kryten are characters in which sci-fi sitcom?',
            answer: 'Red Dwarf'
        },
        {
            category: 'Sitcom',
            question: 'True or false: Fleabag was originally a one-woman play',
            answer: 'True'
        },
        {
            category: 'Sitcom',
            question: 'What is the name of the cafe in Friends?',
            answer: 'Central Perk'
        },
        {
            category: 'Sitcom',
            question: '“What’s Occurring?” is often asked by which Gavin and Stacey character?',
            answer: 'Nessa'
        },
        {
            category: 'Sitcom',
            question: 'In Outnumbered, what is the surname of Jake, Ben and Karen?',
            answer: 'Brockman'
        },
        {
            category: 'Hard',
            question: 'Can you recite the mathematical constant Pye to five decimal places?',
            answer: 3.14159
        },
        {
            category: 'Hard',
            question: 'Can you name all the living presidents of the United States of America?',
            answer: 'Jimmy Carter, George W Bush, Bill Clinton, Barack Obama, Donald Trump'
        },
        {
            category: 'Hard',
            question: 'How many parliamentary seats are returned to the UK Houses of Parliament?',
            answer: 650
        },
        {
            category: 'Hard',
            question: 'How many noble gasses are there? And can you name them all?',
            answer: 'Six. helium (He), neon (Ne), argon (Ar), krypton (Kr), xenon (Xe) radon (Rn)'
        },
        {
            category: 'Hard',
            question: 'What is the capital city of Slovakia?',
            answer: 'Bratislava'
        },
        {
            category: 'Hard',
            question: 'Paul David Hewson is the real name of what famous rock musician?',
            answer: 'Bono (U2)'
        },
        {
            category: 'Hard',
            question: 'How many miles plus 514 yards is the British steeplechase The Grand National?',
            answer: 'Four miles. (4 miles 514 yards in total)'
        },
        {
            category: 'Hard',
            question: 'The letterbox of 10 Downing Street in London has an inscription that reads”First Lord of…” what?',
            answer: 'the Treasury'
        },
        {
            category: 'Hard',
            question: 'Where was Queen frontman Freddie Mercury born?',
            answer: 'Zanzibar, Tanzania'
        },
        {
            category: 'Hard',
            question: 'After Queen Elizabeth II, only four other English monarchs have reached 50 years on the throne – can you name them?',
            answer: 'Victoria, Edward III, Henry III, George III (James VI of Scotland ruled more than 50 years in Scotland, and was crowned during his reign James I of England – but didn’t reign England for 50 years)'
        },
        {
            category: 'Hard',
            question: 'What does the Japanese “sumimasen” translate to in English?',
            answer: 'Excuse me'
        },
        {
            category: 'Hard',
            question: 'Switzerland is made up of how many cantons?',
            answer: 26
        },
        {
            category: 'Hard',
            question: 'Cholecystectomy is the name for the medical removal of what body part?',
            answer: 'Gallbladder'
        },
        {
            category: 'Hard',
            question: 'How many million miles away is the sun from the Earth? (within 5 million miles)',
            answer: '93 million miles (avg)'
        },
        {
            category: 'Hard',
            question: 'What trees, often found in California and which can grow up to 300 feet in height, are recognised as the tallest type in the world?',
            answer: 'Redwoods (Sequoia sempervirens)'
        },
        {
            category: 'Hard',
            question: 'Since 2016, the Periodic Table has how many confirmed elements?',
            answer: 118
        },
        {
            category: 'Hard',
            question: 'In what year was Radio Times magazine first published?',
            answer: 1923
        },
        {
            category: 'Hard',
            question: 'What was film star John Wayne’s real name?',
            answer: 'Marion Michael Morrison'
        },
        {
            category: 'Hard',
            question: 'Who is credited with the following quote? “The fear of death follows from the fear of life. A man who loves fully is prepared to die at any time”',
            answer: 'Mark Twain'
        },
        {
            category: 'Hard',
            question: 'On what pacific island did the mutineers of HMS Bounty settle in 1790?',
            answer: 'Pitcairn Island'
        },
        {
            category: 'Hard',
            question: 'Which gas were British World War Two barrage balloons filled with?',
            answer: 'Hydrogen'
        },
        {
            category: 'Hard',
            question: 'Where in the human body would you find the medulla oblongata?',
            answer: 'The Brain'
        },
        {
            category: 'Hard',
            question: 'Which King of England was crowned on Christmas Day?',
            answer: 'William I (William the Conqueror)'
        },
        {
            category: 'Hard',
            question: 'Which political figure became Baronness of Kesteven?',
            answer: 'Margaret Thatcher'
        },
        {
            category: 'Hard',
            question: 'Which geothermal Icelandic tourist attraction has the same name as a 1980 film?',
            answer: 'Blue Lagoon'
        },
        {
            category: 'Hard',
            question: 'The Bahamas translates to what in English?',
            answer: 'The Shallows'
        },
        {
            category: 'Hard',
            question: 'Which publication documents all proceedings of the British Parliament?',
            answer: 'Hansard'
        },
        {
            category: 'Hard',
            question: 'What was the only painting sold by Vincent van Gogh during his lifetime?',
            answer: 'The Red Vineyard'
        },
        {
            category: 'Hard',
            question: 'Which Shakespeare character kills Tybalt and Paris?',
            answer: 'Romeo'
        },
        {
            category: 'Hard',
            question: 'What colour is found on 75% of the world’s flags?',
            answer: 'Red'
        },
        {
            category: 'Family friendly',
            question: 'How many zeros are there in the number one thousand?',
            answer: 'Three (1,000)'
        },
        {
            category: 'Family friendly',
            question: 'Who is Anna’s sister in the Disney film Frozen?',
            answer: 'Elsa'
        },
        {
            category: 'Family friendly',
            question: 'What is a young sheep called?',
            answer: 'A lamb'
        },
        {
            category: 'Family friendly',
            question: 'How many weeks are in a year?',
            answer: 52
        },
        {
            category: 'Family friendly',
            question: 'In what country is the Great Barrier Reef?',
            answer: 'Australia'
        },
        {
            category: 'Family friendly',
            question: 'What does water turn into when it is frozen solid?',
            answer: 'Ice'
        },
        {
            category: 'Family friendly',
            question: 'What is half of the number fifty?',
            answer: 25
        },
        {
            category: 'Family friendly',
            question: 'Name as many planets in our solar system as you can (a point for each)',
            answer: 'Earth, Jupiter, Saturn, Mars, Neptune, Mercury, Uranus, Venus'
        },
        {
            category: 'Family friendly',
            question: 'What is the biggest ocean on Earth?',
            answer: 'Pacific'
        },
        {
            category: 'Family friendly',
            question: 'When Humpty Dumpty was sitting on a wall, what happened next?',
            answer: 'He had a great fall (he fell off the wall)'
        },
        {
            category: 'Family friendly',
            question: 'In Peppa Pig, what is Peppa’s little brother called?',
            answer: 'George'
        },
        {
            category: 'Family friendly',
            question: 'Which one of these is a fish: A shark, a whale or a dolphin?',
            answer: 'Shark (dolphins and whales are both aquatic mammals)'
        },
        {
            category: 'Family friendly',
            question: 'What do we call someone who travels to space in a rocket?',
            answer: 'An astronaut'
        },
        {
            category: 'Family friendly',
            question: 'What is the little mermaid’s name in the Disney film?',
            answer: 'Ariel'
        },
        {
            category: 'Family friendly',
            question: 'How many colours are there in the rainbow? (and a bonus for naming them)',
            answer: 'Seven (Red, orange, yellow, green, blue, indigo, violet)'
        },
        {
            category: 'Family friendly',
            question: 'What do caterpillars turn into?',
            answer: 'Butterflies'
        },
        {
            category: 'Family friendly',
            question: 'In what country are the pyramids?',
            answer: 'Egypt'
        },
        {
            category: 'Family friendly',
            question: 'What is the name of Mickey Mouse’s girlfriend?',
            answer: 'Minnie Mouse'
        },
        {
            category: 'Family friendly',
            question: 'What is the tallest animal in the world?',
            answer: 'Giraffe (adult giraffes can grow up to nearly 6 meters in height – mainly thanks to its very long neck)'
        },
        {
            category: 'Family friendly',
            question: 'Paris is the capital city of which country?',
            answer: 'France'
        },
        {
            category: 'Literature',
            question: 'In Pride and Prejudice, who does Jane Bennet marry?',
            answer: 'Charles Bingley'
        },
        {
            category: 'Literature',
            question: 'Name all four March sisters in Louisa May Alcott’s Little Women.',
            answer: 'Margaret/Meg, Josephine/Jo, Elizabeth/Beth and Amy'
        },
        {
            category: 'Literature',
            question: 'Which Sally Rooney novel has recently been adapted into a BBC Three drama?',
            answer: 'Normal People'
        },
        {
            category: 'Literature',
            question: 'Who is the current Poet Laureate?',
            answer: 'Simon Armitage'
        },
        {
            category: 'Literature',
            question: 'What is the name of Harper Lee’s second novel, published in 2015',
            answer: 'Go Set a Watchman'
        },
        {
            category: 'Literature',
            question: 'In The Great Gatsby, which Long Island village does Jay Gatsby live in?',
            answer: 'West Egg'
        },
        {
            category: 'Literature',
            question: 'Which two authors won the Booker Prize in 2019?',
            answer: 'Margaret Atwood and Bernadine Evaristo'
        },
        {
            category: 'Literature',
            question: 'What is the name of the fourth book in the Harry Potter series?',
            answer: 'Harry Potter and the Goblet of Fire'
        },
        {
            category: 'Literature',
            question: 'I Know Why the Caged Bird Sings is the autobiography of which American writer and poet?',
            answer: 'Maya Angelou'
        },
        {
            category: 'Literature',
            question: 'Which country is Aesop’s Fables believed to originate from?',
            answer: 'Greece'
        },
        {
            category: 'Literature',
            question: 'What is the novel Frankenstein’s alternative name?',
            answer: 'The Modern Prometheus'
        },
        {
            category: 'Literature',
            question: 'Which Emily Brontë novel is the inspiration for a Kate Bush song?',
            answer: 'Wuthering Heights'
        },
        {
            category: 'Literature',
            question: 'The Hunger Games young adult series was written by which author?',
            answer: 'Suzanne Collins'
        },
        {
            category: 'Literature',
            question: 'Fifty Shades of Grey by E L James was originally fan fiction based on which popular supernatural book series?',
            answer: 'The Twilight Saga'
        },
        {
            category: 'Literature',
            question: 'George Orwell’s Nineteen Eighty-Four was published in which year?',
            answer: 1949
        },
        {
            category: 'Literature',
            question: 'What is the name of the pig in EB White’s Charlotte’s Web',
            answer: 'Wilbur'
        },
        {
            category: 'Literature',
            question: 'How many novels did Roald Dahl write?',
            answer: 19
        },
        {
            category: 'Literature',
            question: 'Name all three books in Phillip Pullman’s His Dark Materials series.',
            answer: 'Northern Lights (UK)/The Golden Compass (US), The Subtle Knife, The Amber Spyglass'
        },
        {
            category: 'Literature',
            question: 'What is the name of the publication for which William Boot works in Evelyn Waugh’s Scoop?',
            answer: 'Daily Beast'
        },
        {
            category: 'Literature',
            question: 'Who wrote The Picture of Dorian Gray?',
            answer: 'Oscar Wilde'
        },
        {
            category: 'Science',
            question: 'Humans and chimpanzees share roughly how much DNA?',
            answer: '98%'
        },
        {
            category: 'Science',
            question: 'What is the most abundant gas in the Earth’s atmosphere?',
            answer: 'Nitrogen'
        },
        {
            category: 'Science',
            question: 'Roughly how long does it take for the sun’s light to reach Earth – 8 minutes, 8 hours or 8 days?',
            answer: '8 minutes'
        },
        {
            category: 'Science',
            question: 'Which famous Britsh physicist wrote A Brief History of Time?',
            answer: 'Stephen Hawking'
        },
        {
            category: 'Science',
            question: 'At what temperature are Celsius and Fahrenheit equal?',
            answer: -40
        },
        {
            category: 'Science',
            question: 'What modern-day country was Marie Curie born in?',
            answer: 'Poland'
        },
        {
            category: 'Science',
            question: 'What is the biggest planet in our solar system?',
            answer: 'Jupiter'
        },
        {
            category: 'Science',
            question: 'What name is given for the number of protons found in the nucleus of an atom?',
            answer: 'Atomic number'
        },
        {
            category: 'Science',
            question: 'How many vertebrae does the average human possess?',
            answer: 33
        },
        {
            category: 'Science',
            question: 'What was the name of the first man-made satellite launched by the Soviet Union in 1957?',
            answer: 'Sputnik 1'
        },
        {
            category: 'Science',
            question: 'Which oath of ethics taken by doctors is named after an Ancient Greek physician?',
            answer: 'Hippocratic Oath'
        },
        {
            category: 'Science',
            question: 'What is a material that will not carry an electrical charge called?',
            answer: 'Insulator'
        },
        {
            category: 'Science',
            question: 'Which Apollo moon mission was the first to carry a lunar rover?',
            answer: 'Apollo 15'
        },
        {
            category: 'Science',
            question: 'How many teeth does an adult human have?',
            answer: 32
        },
        {
            category: 'Science',
            question: 'What is the study of mushrooms called?',
            answer: 'Mycology'
        },
        {
            category: 'Science',
            question: 'On the periodic table, what symbol stands for silver?',
            answer: 'Ag'
        },
        {
            category: 'Science',
            question: 'Which two planets lack moons?',
            answer: 'Mercury and Venus'
        },
        {
            category: 'Science',
            question: 'How many hearts do octopuses have?',
            answer: 'Three'
        },
        {
            category: 'Science',
            question: 'In electronics, what does DC stand for?',
            answer: 'Direct Current'
        },
        {
            category: 'Science',
            question: 'What was the name of the first supersonic passenger airliner?',
            answer: 'Concorde'
        },
        {
            category: 'Technology',
            question: 'The current richest man in the world Jeff Bezos is CEO and President of which online retailer?',
            answer: 'Amazon'
        },
        {
            category: 'Technology',
            question: 'Which social media app only lets you view pictures and messages for a limited time?',
            answer: 'Snapchat'
        },
        {
            category: 'Technology',
            question: 'What does a Geiger Counter measure?',
            answer: 'Radiation'
        },
        {
            category: 'Technology',
            question: 'Which prestigious university did Microsoft founder Bill Gates drop out of?',
            answer: 'Harvard'
        },
        {
            category: 'Technology',
            question: 'What year did the first Apple iPhone launch?',
            answer: 2007
        },
        {
            category: 'Technology',
            question: 'What unit of length is equal to around 5.8 trillion miles?',
            answer: 'Light Year'
        },
        {
            category: 'Technology',
            question: 'What does CPU stand for?',
            answer: 'Central Processing Unit'
        },
        {
            category: 'Technology',
            question: 'Created in 1990, what was the name of the first internet search engine?',
            answer: 'Archie'
        },
        {
            category: 'Technology',
            question: 'In which decade was the Sony Walkman launched?',
            answer: '1970s'
        },
        {
            category: 'Technology',
            question: 'Was electronics company Nintendo founded in 1889, 1946 or 1975?',
            answer: '1889 (Yes, really)'
        },
        {
            category: 'Technology',
            question: 'From 2017 onwards, how many characters long can tweets be?',
            answer: '280 characters'
        },
        {
            category: 'Technology',
            question: 'What is the name of Elon Musk’s aerospace company?',
            answer: 'SpaceX'
        },
        {
            category: 'Technology',
            question: 'Which duo invented the aeroplane?',
            answer: 'The Wright Brothers – Orville and Wilbur Wright'
        },
        {
            category: 'Technology',
            question: 'What is the name of the classic 1972 arcade game based on table tennis?',
            answer: 'Pong'
        },
        {
            category: 'Technology',
            question: 'What does the term LASER stand for?',
            answer: 'Light Amplification by Stimulated Emission of Radiation'
        },
        {
            category: 'Technology',
            question: 'What does LG stand for in LG Electronics?',
            answer: 'Lucky-Goldstar'
        },
        {
            category: 'Technology',
            question: 'Solar power generates electricity from what source?',
            answer: 'The Sun'
        },
        {
            category: 'Technology',
            question: 'What is the name of the British computer scientist who invented the World Wide Web in 1989?',
            answer: 'Tim Berners-Lee'
        },
        {
            category: 'Technology',
            question: 'Originally Amazon only sold which product?',
            answer: 'Books'
        },
        {
            category: 'Technology',
            question: 'In what year was the first transatlantic radio broadcast?',
            answer: 1901
        },
        {
            category: '2000s music',
            question: 'What is the name of the most popular song of the 2000s, performed by a Pop Idol winner?',
            answer: 'Evergreen, by Will Young'
        },
        {
            category: '2000s music',
            question: 'Which X Factor star had the best performing single of the 2000s?',
            answer: 'Alexandra Burke, with her winner’s song Hallelujah'
        },
        {
            category: '2000s music',
            question: 'Rihanna was already a successful popstar by this one, but which 2007 hit with Jay-Z propelled her into superstar status?',
            answer: 'Umbrella'
        },
        {
            category: '2000s music',
            question: 'The world met Lady Gaga in 2008 with her huge song Just Dance. But when it hit the UK charts in 2009, where did it place in its first week?',
            answer: 'Number three. It was Number One the following week.'
        },
        {
            category: '2000s music',
            question: 'Beyoncé’s Single Ladies famously lost the accolade for Best Female Videos at the 2009 VMAs. Who won and why was the moment marred by controversy?',
            answer: 'Taylor Swift, who was interrupted by Kanye West saying “Beyoncé had the best video of all time”.'
        },
        {
            category: '2000s music',
            question: 'The Black Eyed Peas hit the charts in 2003 with their smash, Where Is the Love? Can you name all of the original members?',
            answer: 'will.i.am, Fergie, apl.de.ap, Taboo'
        },
        {
            category: '2000s music',
            question: 'Eminem’s Lose Yourself not only performed well in the charts, but it was the soundtrack to a cult movie. What’s the film’s title?',
            answer: '8 Mile'
        },
        {
            category: '2000s music',
            question: 'Which 2009 song by Mika has the following lyrics: “I could be brown, I could be blue, I could be violet sky”?',
            answer: 'Grace Kelly'
        },
        {
            category: '2000s music',
            question: 'Which indie rock band’s first album is called Employment?',
            answer: 'Kaiser Chiefs'
        },
        {
            category: '2000s music',
            question: 'What year did Kings of Leon release their chart-topper Sex on Fire?',
            answer: 2008
        },
        {
            category: '2000s music',
            question: 'Which British boyband made a comeback with Patience?',
            answer: 'Take That'
        },
        {
            category: '2000s music',
            question: 'What’s the name of The Killers’ debut album from 2004?',
            answer: 'Hot Fuss'
        },
        {
            category: '2000s music',
            question: 'Which band had the bestselling album of 2000?',
            answer: 'The Beatles, 1'
        },
        {
            category: '2000s music',
            question: 'In which year did Katy Perry release her chart-topper, I Kissed a Girl? 2007, 2008, 2009?',
            answer: 2008
        },
        {
            category: '2000s music',
            question: 'Which cartoon character beat Westlife to the Christmas number one spot in 2000?',
            answer: 'Bob the Builder with Can We Fix It? beat Westlife’s What Makes a Man'
        },
        {
            category: '2000s music',
            question: 'Arctic Monkeys took over the indie scene in 2005 with I Bet You Look Good on the Dancefloor. What’s the first line of the song?',
            answer: '“Stop making the eyes at me I’ll stop making the eyes at you”'
        },
        {
            category: '2000s music',
            question: 'In 2009, Jay-Z wrote a song for his hometown which took the charts by storm. Name the city and the song.',
            answer: 'New York City, Empire State of Mind'
        },
        {
            category: '2000s music',
            question: 'In which year did Snoop Dogg release his club-classic Drop It Like It’s Hot?',
            answer: 2004
        },
        {
            category: '2000s music',
            question: 'Which Britain’s Got Talent winner had the best-selling album of 2009?',
            answer: 'Susan Boyle'
        },
        {
            category: '2000s music',
            question: 'Simply Red released a cover of You Make Me Feel Brand New in 2003 – who originally sang it?',
            answer: 'The Stylistics in 1973'
        },
        {
            category: '90s music',
            question: 'Name the members of the band Blur (a point for each)',
            answer: 'Damon Albarn, Graham Coxon, Alex James, Dave Rowntree'
        },
        {
            category: '90s music',
            question: 'What’s the name of Britney Spears’ first single, released in 1998?',
            answer: '… Baby One More Time'
        },
        {
            category: '90s music',
            question: 'What is the name of the first album by Oasis?',
            answer: 'Definitely, Maybe'
        },
        {
            category: '90s music',
            question: 'Name all the original members of Take That (a point for each)',
            answer: 'Robbie Williams, Jason Orange, Mark Owen, Gary Barlow, Howard Donald'
        },
        {
            category: '90s music',
            question: 'Everybody’s Free to Wear Sunscreen was a 1999 hit for which Australian artist, best known for film directing?',
            answer: 'Baz Luhrmann'
        },
        {
            category: '90s music',
            question: 'Which band had a 1997 hit with the song The Drugs Don’t Work?',
            answer: 'The Verve'
        },
        {
            category: '90s music',
            question: 'What was the name of the Children’s series on which S Club 7 began their musical career?',
            answer: 'Miami 7'
        },
        {
            category: '90s music',
            question: 'Which 1998 Madonna album featured the hit single Frozen?',
            answer: 'Ray of Light'
        },
        {
            category: '90s music',
            question: 'In which year of the nineties did Nirvana frontman Kurt Cobain die?',
            answer: 1994
        },
        {
            category: '90s music',
            question: 'What were REM losing in their 1991 hit single?',
            answer: 'Their religion (Losing My Religion)'
        },
        {
            category: '90s music',
            question: 'Whitney Houston’s rendition of I Will Always Love You shot into the charts in 1992 after it was featured in which Hollywood film?',
            answer: 'The Bodyguard'
        },
        {
            category: '90s music',
            question: 'According to Natalie Inbruglia in 1997, “Nothing’s right, I’m…” what?',
            answer: 'Torn'
        },
        {
            category: '90s music',
            question: 'Sinead O’Connor had a big chart hit in 1990 with which song written by Prince?',
            answer: 'Nothing Compares 2 U'
        },
        {
            category: '90s music',
            question: 'Who was the lead guitarist in The Stone Roses?',
            answer: 'John Squire'
        },
        {
            category: '90s music',
            question: 'Which song by Manchester band James was the lead song in the 1999 America Pie film soundtrack?',
            answer: 'Laid'
        },
        {
            category: '90s music',
            question: 'Name the Bryan Adams song that was featured in the film Robin Hood Prince of Thieves and spent 16 weeks at the top of the UK charts in 1991',
            answer: '(Everything I do) I Do It For You'
        },
        {
            category: '90s music',
            question: 'In 1995, The Beatles released their Anthology albums, featuring rare and never-before-heard demos. What was the name of the new song that they released ahead of these albums?',
            answer: 'Free As A Bird'
        },
        {
            category: '90s music',
            question: 'How many members were in the Backstreet Boys?',
            answer: 'Five. Nick Carter, Kevin Richardson, Brian Littrell, AJ McLean, Howie Dorough'
        },
        {
            category: '90s music',
            question: 'Who is the lead singer of Pearl Jam?',
            answer: 'Eddie Vedder'
        },
        {
            category: '90s music',
            question: 'Radiohead sang about Fake Plastic what?',
            answer: 'Trees'
        },
        {
            category: '80s music',
            question: 'What year was Live Aid?',
            answer: 1985
        },
        {
            category: '80s music',
            question: 'Who sang 1984 hit All Cried Out?',
            answer: 'Alison Moyet'
        },
        {
            category: '80s music',
            question: 'Which popular AC/DC album was the first to feature new vocalist Brian Johnson?',
            answer: 'Back in Black'
        },
        {
            category: '80s music',
            question: 'Who sang the title track of 80s Bond film The Living Daylights?',
            answer: 'A-ha'
        },
        {
            category: '80s music',
            question: 'In which iconic music video do Queen parody Coronation Street?',
            answer: 'I Want to Break Free'
        },
        {
            category: '80s music',
            question: 'Now associated with rickrolling, which 1987 Rick Astley song became number one in 25 countries?',
            answer: 'Never Gonna Give You Up'
        },
        {
            category: '80s music',
            question: 'Which single gave Whitney Houston her first UK number one in 1985?',
            answer: 'Saving All My Love For You'
        },
        {
            category: '80s music',
            question: 'What was the best-selling single of the decade in the UK?',
            answer: 'Do They Know It’s Christmas?'
        },
        {
            category: '80s music',
            question: 'Which band was awaiting a Letter From America?',
            answer: 'The Proclaimers'
        },
        {
            category: '80s music',
            question: 'Who went straight to number one in 1981 with Stand and Deliver?',
            answer: 'Adam and the Ants'
        },
        {
            category: '80s music',
            question: 'Who was Christmas Number One in 1988 with Mistletoe and Wine?',
            answer: 'Cliff Richard'
        },
        {
            category: '80s music',
            question: 'Which famous actor was waiting for Bananarama in 1984?',
            answer: 'Robert de Niro'
        },
        {
            category: '80s music',
            question: 'Who is the lead singer of the band Frankie Goes To Hollywood?',
            answer: 'Holly Johnson'
        },
        {
            category: '80s music',
            question: 'Bobby G, Cheryl Baker, Mike Nolan and Jay Aston are members of which band?',
            answer: 'Bucks Fizz'
        },
        {
            category: '80s music',
            question: 'How old were George Michael and Andrew Ridgely when they wrote Careless Whisper – 17, 19, or 22?',
            answer: 17
        },
        {
            category: '80s music',
            question: 'Which U2 album became the fastest-selling album in British history at the time, once released in 1987?',
            answer: 'The Joshua Tree'
        },
        {
            category: '80s music',
            question: 'Which iconic Simple Minds song plays during the opening and closing credits of The Breakfast Club?',
            answer: 'Don’t You (Forget About Me)'
        },
        {
            category: '80s music',
            question: 'Which 1981 Journey song failed to crack the UK Top 40 on release, then reached number 6 in 2009?',
            answer: 'Don’t Stop Believin’'
        },
        {
            category: '80s music',
            question: 'Name the song: “It’s gonna take a lot to drag me away from you, there’s nothing that a hundred men or more could ever do”',
            answer: 'Africa by Toto'
        },
        {
            category: '80s music',
            question: 'Which singer-songwriter’s real name is Michael Barratt?',
            answer: 'Shakin’ Stevens'
        },
        {
            category: '70s music',
            question: 'In his 1979 hit Escape, Rupert Holmes enjoys getting caught in the rain with which cocktail?',
            answer: 'Piña Colada'
        },
        {
            category: '70s music',
            question: 'Which legendary British band released their final album in May 1970?',
            answer: 'The Beatles'
        },
        {
            category: '70s music',
            question: 'Errol Brown was the lead singer of which band?',
            answer: 'Hot Chocolate'
        },
        {
            category: '70s music',
            question: 'Who sang the title song of 70s Bond film The Man with the Golden Gun?',
            answer: 'Lulu'
        },
        {
            category: '70s music',
            question: 'Which Irish rock band sang The Boys Are Back In Town?',
            answer: 'Thin Lizzy'
        },
        {
            category: '70s music',
            question: 'Bridge Over Troubled Water was the final album of which folk-rock duo?',
            answer: 'Simon & Garfunkel'
        },
        {
            category: '70s music',
            question: 'Silver Lady singer David Soul also starred in which iconic action TV series?',
            answer: 'Starsky & Hutch'
        },
        {
            category: '70s music',
            question: 'What was the name of David Bowie’s flamboyant alter ego?',
            answer: 'Ziggy Stardust'
        },
        {
            category: '70s music',
            question: 'What year was the Sony Walkman released?',
            answer: 1979
        },
        {
            category: '70s music',
            question: 'Eurovision sensation ABBA came from which country?',
            answer: 'Sweden'
        },
        {
            category: '70s music',
            question: 'Name the song: “Well, you can tell by the way I use my walk, I’m a woman’s man, no time to talk”',
            answer: 'Stayin’ Alive by the Bee Gees'
        },
        {
            category: '70s music',
            question: 'What was the best selling single of the decade in the UK?',
            answer: 'Mull of Kintyre by Wings'
        },
        {
            category: '70s music',
            question: 'Which rock band released 70s albums Paranoid and Masters of Reality?',
            answer: 'Black Sabbath'
        },
        {
            category: '70s music',
            question: 'What was the best selling film soundtrack of the 1970s in the UK?',
            answer: 'Saturday Night Fever'
        },
        {
            category: '70s music',
            question: 'Which British band put A Message In A Bottle in 1979?',
            answer: 'The Police'
        },
        {
            category: '70s music',
            question: 'The film Rocketman charts the rise of which 70s superstar?',
            answer: 'Elton John'
        },
        {
            category: '70s music',
            question: 'What is the real name of K.C. from K.C. and the Sunshine Band?',
            answer: 'Harry Wayne Casey'
        },
        {
            category: '70s music',
            question: 'Which song from Grease sold the most singles in the UK?',
            answer: 'You’re the One That I Want'
        },
        {
            category: '70s music',
            question: 'Which Led Zeppelin song famously references Norse mythology?',
            answer: 'Immigrant Song'
        },
        {
            category: '70s music',
            question: 'Which member of the Electric Light Orchestra left to form Wizzard in July 1972?',
            answer: 'Roy Wood'
        },
        {
            category: '60s music',
            question: 'True or false: Penny Lane is named after a real street in London',
            answer: 'False – it’s a street in Liverpool'
        },
        {
            category: '60s music',
            question: 'Who is the lead singer of The Who?',
            answer: 'Roger Daltrey'
        },
        {
            category: '60s music',
            question: 'It’s Now or Never was the first 60s number one for which musical icon?',
            answer: 'Elvis Presley'
        },
        {
            category: '60s music',
            question: 'Who sang the title track of 60s Bond film Thunderball?',
            answer: 'Tom Jones'
        },
        {
            category: '60s music',
            question: 'Which Daydream Believer band initially began as a fictional band on a sitcom?',
            answer: 'The Monkees'
        },
        {
            category: '60s music',
            question: 'Peter, Paul and Mary famously sang Leaving on a Jet Plane – but who wrote and sang the original version?',
            answer: 'John Denver'
        },
        {
            category: '60s music',
            question: 'What is the best selling single of the 1960s in the UK?',
            answer: 'She Loves You by The Beatles'
        },
        {
            category: '60s music',
            question: 'Which UK comedian had the best-selling UK single of 1965?',
            answer: 'Ken Dodd with Tears'
        },
        {
            category: '60s music',
            question: 'The Animals formed in which UK city?',
            answer: 'Newcastle'
        },
        {
            category: '60s music',
            question: 'What was the name of Tom Jones’ first number one in 1965, later popularised by Carlton in The Fresh Prince of Bel-Air?',
            answer: 'It’s Not Unusual'
        },
        {
            category: '60s music',
            question: 'Which iconic BBC music show premiered on 1st January 1964?',
            answer: 'Top of the Pops'
        },
        {
            category: '60s music',
            question: 'Itsy Bitsy Teenie Weenie Yellow Polkadot Bikini was a 1990 hit for Bombalurina, but who sang the 1960 original?',
            answer: 'Brian Hyland'
        },
        {
            category: '60s music',
            question: 'Which band were recording their first album at Abbey Road while The Beatles were recording Sgt. Pepper’s Lonely Hearts Band?',
            answer: 'Pink Floyd'
        },
        {
            category: '60s music',
            question: 'How many number one albums did Cilla Black have in the 60s?',
            answer: 'None – her first number one album was in 2015'
        },
        {
            category: '60s music',
            question: 'Which American rocker was the final performer at Woodstock festival in 1969?',
            answer: 'Jimi Hendrix'
        },
        {
            category: '60s music',
            question: 'Jim Morrison was the lead singer of which controversial band?',
            answer: 'The Doors'
        },
        {
            category: '60s music',
            question: 'Name the song: “All the leaves are brown, And the sky is grey…”',
            answer: 'California Dreamin by The Mamas and The Papas'
        },
        {
            category: '60s music',
            question: 'How many number ones did The Beatles have during the 1960s in the UK?',
            answer: 17
        },
        {
            category: '60s music',
            question: 'Gimme Shelter and Paint It Black were hits for which legendary British band?',
            answer: 'The Rolling Stones'
        },
        {
            category: '60s music',
            question: 'Which blonde bombshell became the face of country music in the 60s?',
            answer: 'Dolly Parton'
        },
        {
            category: 'Soaps',
            question: 'Which EastEnders character is mum to twins Riley and Chatham?',
            answer: 'Karen Taylor'
        },
        {
            category: 'Soaps',
            question: 'What year did EastEnders start?',
            answer: 1985
        },
        {
            category: 'Soaps',
            question: 'Who was the biological father of Kat Moon’s son Tommy in EastEnders?',
            answer: 'Michael Moon (Alfie’s cousin)'
        },
        {
            category: 'Soaps',
            question: 'Who became landlord of EastEnders pub the Queen Vic on Christmas Day 2013?',
            answer: 'Mick Carter'
        },
        {
            category: 'Soaps',
            question: 'What was the name of Yasmeen Metcalfe’s first husband in Coronation Street?',
            answer: 'Sharif Nazir'
        },
        {
            category: 'Soaps',
            question: 'In Coronation Street, what was the name of Asha and Aadi Alahan’s late mother?',
            answer: 'Sunita'
        },
        {
            category: 'Soaps',
            question: 'Which Coronation Street character made their first appearance in 1974, and is the fourth longest-serving cast member?',
            answer: 'Gail'
        },
        {
            category: 'Soaps',
            question: 'Name the much-loved Coronation Street pet dog who was put down in April 2020 after 14 years on the cobbles.',
            answer: 'Eccles'
        },
        {
            category: 'Soaps',
            question: 'Prior to the infamous plane crash in 1993, what was the village where Emmerdale is set known as?',
            answer: 'Beckindale'
        },
        {
            category: 'Soaps',
            question: 'Ephraim Monk are the fictional brewery supplying beer to which famous soap pub?',
            answer: 'The Woolpack (Emmerdale)'
        },
        {
            category: 'Soaps',
            question: 'In 2019, which Emmerdale character discovered their long-lost father was a wrestler called Bear Wolf?',
            answer: 'Paddy Kirk'
        },
        {
            category: 'Soaps',
            question: 'In Emmerdale, what is ‘Chas’ short for in Chas Dingle?',
            answer: 'Chastity'
        },
        {
            category: 'Soaps',
            question: 'In which soap did future Doctor Who companion Mandip Gill play Phoebe McQueen from 2012-2015?',
            answer: 'Hollyoaks'
        },
        {
            category: 'Soaps',
            question: 'Step-sisters Mandy and Cindy have both been married to which Hollyoaks character?',
            answer: 'Tony Hutchinson'
        },
        {
            category: 'Soaps',
            question: 'Which former X factor contestant plays Lisa Loveday in Hollyoaks?',
            answer: 'Rachel Adedeji'
        },
        {
            category: 'Soaps',
            question: 'Which character did Adam Rickitt play in Coronation Street before joining Hollyoaks as Kyle Kelly?',
            answer: 'Nick Tilsley'
        },
        {
            category: 'Soaps',
            question: 'He’s best known as Toadie Rebecchi, but what is the long-running Neighbours character’s actual first name? (Not the actor name)',
            answer: 'Jarrod'
        },
        {
            category: 'Soaps',
            question: 'Who has been married more times – Coronation Street’s Ken Barlow, EastEnders’ Ian Beale or Neighbours’ Paul Robinson?',
            answer: 'Neighbours’ Paul Robinson – 6 (Ken 4, twice to Deirdre, Ian 5, twice to Jane)'
        },
        {
            category: 'Soaps',
            question: 'Which future Hollywood star played Mike Young in Neighbours and David Croft in Home and Away?',
            answer: 'Guy Pearce'
        },
        {
            category: 'Soaps',
            question: 'Which future star of the Avengers movie franchise played Kim Hyde in Home and Away?',
            answer: 'Chris Hemsworth'
        },
        {
            category: 'Netflix',
            question: 'A zoo owner spirals out of control amid a cast of eccentric characters in this true murder-for-hire story from the underworld of big cat breeding',
            answer: 'Tiger King: Murder, Mayhem and Madness'
        },
        {
            category: 'Netflix',
            question: 'On the shores of paradise, gorgeous singles meet and mingle. But there’s a twist. To win $100,000 grand prize they’ll have to give up sex.',
            answer: 'Too Hot to Handle'
        },
        {
            category: 'Netflix',
            question: 'After his business partner cheats a dangerous client, financial adviser Marty must devise a radical plan to save the lives of himself and his family.',
            answer: 'Ozark'
        },
        {
            category: 'Netflix',
            question: 'A twisted criminal’s gruesome videos drive a group of amateur online sleuths to launch a risky manhunt that pulls them into a dark underworld.',
            answer: 'Don’t F**k with Cats: Hunting an Internet Killer'
        },
        {
            category: 'Netflix',
            question: 'Two 19th Century footballers on opposite sides of a class divide navigate professional and personal turmoil to change the game – and England – forever.',
            answer: 'The English Game'
        },
        {
            category: 'Netflix',
            question: 'In the late 1970s two FBI agents expand criminal science by delving into the psychology of murder and getting uneasily close to all-too0real monsters.',
            answer: 'Mindhunter'
        },
        {
            category: 'Netflix',
            question: 'A dangerously charming, intensely obsessive young man goes to extreme measures to insert himself into the lives of those he is transfixed by.',
            answer: 'You'
        },
        {
            category: 'Netflix',
            question: 'A terminally ill chemistry teacher teams with a former student to manufacture crystal meth and secure his family’s future.',
            answer: 'Breaking Bad'
        },
        {
            category: 'Netflix',
            question: 'A hotheaded widow searching for the hit-and-run driver who mowed down her husband befriends an eccentric optimist who isn’t quite what she seems.',
            answer: 'Dead to Me'
        },
        {
            category: 'Netflix',
            question: 'A privileged New Yorker ends up in a women’s prison a past crime catches up with her in this Emmy-winning series from the creators of “Weeds.”',
            answer: 'Orange is the New Black'
        },
        {
            category: 'Netflix',
            question: 'As Alfred the Great defends his kingdom from Norse invaders, Uhtred — born a Saxon but raised by Vikings — seeks to claim his ancestral birthright.',
            answer: 'The Last Kingdom'
        },
        {
            category: 'Netflix',
            question: 'After their Dad’s murder, three siblings move with their mom to his ancestral estate, where they discover magical kets that unlock powers – and secrets.',
            answer: 'Locke and Key'
        },
        {
            category: 'Netflix',
            question: 'Filmed over 13-years, this real-life thriller follows the unprecedented story of two men accused of a grisly crime they may not have committed.',
            answer: 'Making a Murderer'
        },
        {
            category: 'Netflix',
            question: 'When a young boy vanishes, a small town uncovers a mystery involving secret experiments, terrifying supernatural forces and one strange little girl.',
            answer: 'Stranger Things'
        },
        {
            category: 'Netflix',
            question: 'A budding teen psychopath and a rebel hungry for adventure embark on a star-crossed road trip in this darkly comic series based on a graphic novel.',
            answer: 'The End of the F***king World'
        },
        {
            category: 'Netflix',
            question: 'Secrets. Lies. Revenge. Everyone at Liberty Hight has something to hide… and the truth is about to come out.',
            answer: '13 Reasons Why'
        },
        {
            category: 'Netflix',
            question: 'Eight thieves take hostages and lock themselves in the Royal Mint of Spain as a criminal mastermind manipulates the police to carry out his plan.',
            answer: 'Money Heist'
        },
        {
            category: 'Netflix',
            question: 'Angsty Syd navigates high school awkwardness, family drama and an unrequited crush on her best friend while trying to rein in her budding superpowers',
            answer: 'I am Not OK with This'
        },
        {
            category: 'Netflix',
            question: 'As a traumatised reports being raped by an intruder, she faces a whirlwind of emotions — and increasingly skeptical questions from the police.',
            answer: 'Unbelievable'
        },
        {
            category: 'Netflix',
            question: 'Bored with being Lord of Hell, the devil relocates to Los Angeles, where he opens a nightclub and forms a connection with a homicide detective.',
            answer: 'Lucifer'
        },
        {
            category: 'Doctor Who',
            question: 'Name every actor to portray the Doctor in the Doctor Who TV series since 2005 (First Name and Last Name).',
            answer: 'Christopher Eccleston, David Tennant, Matt Smith, Peter Capaldi, Jodie Whitaker, John Hurt, Jo Martin, David Bradley.'
        },
        {
            category: 'Doctor Who',
            question: 'The Ice Warriors come from which planet?',
            answer: 'Mars'
        },
        {
            category: 'Doctor Who',
            question: 'Which EastEnders actor did NOT appear in the 1993 Doctor Who special Dimensions in Time? Pam St Clement/Leslie Grantham/Mike Reid/Wendy Richard',
            answer: 'Leslie Grantham'
        },
        {
            category: 'Doctor Who',
            question: 'Which Doctor has the line “900 years of time and space, and I’ve never been slapped by someone’s mother.”',
            answer: 'The Ninth Doctor/Christopher Eccleston'
        },
        {
            category: 'Doctor Who',
            question: 'What interrupted the first Christopher Eccleston story Rose upon its first TV transmission?',
            answer: 'The sound of Graham Norton hosting Strictly Dance Fever'
        },
        {
            category: 'Doctor Who',
            question: 'What was the Doctor’s nickname while at the Prydonian Academy on Gallifrey?',
            answer: 'Theta Sigma'
        },
        {
            category: 'Doctor Who',
            question: 'What were the last words spoken by the Tenth Doctor before he regenerated?',
            answer: '“I don’t want to go.”'
        },
        {
            category: 'Doctor Who',
            question: 'The Doctor has which ‘type; of TARDIS?',
            answer: 'A Type 40'
        },
        {
            category: 'Doctor Who',
            question: 'Which classic Doctor has encountered the Cybermen, but never had a Cyberman story of their own?',
            answer: 'Jon Pertwee/The Third Doctor'
        },
        {
            category: 'Doctor Who',
            question: 'Complete the following news quote from 2010 “Since the new Doctor Who aired, we have seen a dramatic rise in ___ sales, in the last month sales have increased by 94 percent.”',
            answer: 'Bow tie'
        },
        {
            category: 'Doctor Who',
            question: 'What is the home planet of the Slitheen, and how – exactly – do you spell it?',
            answer: 'Raxacoricofallapatorius'
        },
        {
            category: 'Doctor Who',
            question: 'In 2008 an episode of Doctor Who included two guest stars who would later be cast in main roles on the series. Who were they? (Bonus point – what’s the name of the episode?)',
            answer: 'Peter Capaldi and Karen Gillan in Fires of Pompeii'
        },
        {
            category: 'Doctor Who',
            question: 'Who did Colin Baker play in Doctor Who before he took on the role of the Sixth Doctor?',
            answer: 'Commander Maxil in Arc of Infinity'
        },
        {
            category: 'Doctor Who',
            question: 'How many operators is the TARDIS designed to have?',
            answer: 'Six'
        },
        {
            category: 'Doctor Who',
            question: 'What was the name of the school where the Doctor was working undercover in 2006’s School Reunion?',
            answer: 'Deffrey Vale'
        },
        {
            category: 'Doctor Who',
            question: 'In 2013’s Time of the Doctor, what is the name of the poem Clara reads the Doctor from a Christmas Cracker?',
            answer: 'Thoughts on a Clock'
        },
        {
            category: 'Doctor Who',
            question: 'What magazine did series companion Sarah Jane Smith work for?',
            answer: 'Metropolitan magazine'
        },
        {
            category: 'Doctor Who',
            question: 'How much did the TARDIS used in the 2005 series fetch when it was auctioned in 2010? (Point for closest answer if no one guesses exactly)',
            answer: '£10,800'
        },
        {
            category: 'Doctor Who',
            question: 'Which of the following is not a Doctor Who alien: Krillitane, Jagrafess, Flummox, Toclafane, Atraxi',
            answer: 'Flummox (actually from Dr Seuss)'
        },
        {
            category: 'Doctor Who',
            question: 'What was the name of the fictional council estate where Billie Piper’s Rose Tyler lived?',
            answer: 'The Powell Estate'
        },
        {
            category: 'Marvel',
            question: 'Who made Captain America’s shield?',
            answer: 'Howard Stark'
        },
        {
            category: 'Marvel',
            question: 'Unlike the comics, who created Ultron in Avengers: Age of Ultron?',
            answer: 'Tony Stark/Iron Man'
        },
        {
            category: 'Marvel',
            question: 'Which beloved comic book writer has cameoed in every Marvel film up to Avengers: Endgame?',
            answer: 'Stan Lee'
        },
        {
            category: 'Marvel',
            question: 'Before he was Captain America, which member of the Fantastic Four did Chris Evans play?',
            answer: 'The Human Torch'
        },
        {
            category: 'Marvel',
            question: 'What is Captain America’s shield made out of?',
            answer: 'Vibranium'
        },
        {
            category: 'Marvel',
            question: 'Which infamous aquatic bird cameos in the post-credit scene of Guardians of the Galaxy?',
            answer: 'Howard the Duck'
        },
        {
            category: 'Marvel',
            question: 'What is Black Widow’s real name?',
            answer: 'Natasha Romanoff'
        },
        {
            category: 'Marvel',
            question: 'On what planet was the Soul Stone hidden in Infinity War?',
            answer: 'Vormir'
        },
        {
            category: 'Marvel',
            question: 'Which former Doctor Who companion plays cyborg assassin Nebula?',
            answer: 'Karen Gillan'
        },
        {
            category: 'Marvel',
            question: 'What is the name of Thor’s hammer?',
            answer: 'Mjolnir'
        },
        {
            category: 'Marvel',
            question: 'Which Marvel film did Kenneth Branagh direct?',
            answer: 'Thor'
        },
        {
            category: 'Marvel',
            question: 'Which Hollywood A-lister made a cameo in Thor: Ragnarok playing Loki in an Asgardian play?',
            answer: 'Matt Damon'
        },
        {
            category: 'Marvel',
            question: 'In which film’s post-credit scene did Thanos first appear?',
            answer: 'The Avengers'
        },
        {
            category: 'Marvel',
            question: 'Director Taika Waititi also played which comedic Thor: Ragnarok character?',
            answer: 'Korg'
        },
        {
            category: 'Marvel',
            question: 'Who played Bruce Banner in The Incredible Hulk?',
            answer: 'Edward Norton'
        },
        {
            category: 'Marvel',
            question: 'What Marvel Cinematic Universe film was released first?',
            answer: 'Iron Man'
        },
        {
            category: 'Marvel',
            question: 'What is the name of Black Panther’s home country?',
            answer: 'Wakanda'
        },
        {
            category: 'Marvel',
            question: 'Which former Batman played Spider-Man villain the Vulture?',
            answer: 'Michael Keaton'
        },
        {
            category: 'Marvel',
            question: 'What is the name of the microscopic universe Ant-Man travels to when he goes sub-atomic?',
            answer: 'Quantum Realm'
        },
        {
            category: 'Marvel',
            question: 'Who was Tony Stark’s favourite band, whose songs feature in the Iron Man movies?',
            answer: 'AC/DC'
        },
        {
            category: 'Marvel',
            question: 'What species is Loki revealed to be?',
            answer: 'Frost Giant'
        },
        {
            category: 'Marvel',
            question: 'Which fictional detective have Robert Downey Jr. and Benedict Cumberbatch both famously played?',
            answer: 'Sherlock Holmes'
        },
        {
            category: 'Marvel',
            question: 'Before becoming Vision, what is the name of Iron Man’s A.I. butler?',
            answer: 'Jarvis'
        },
        {
            category: 'Marvel',
            question: 'What is the only Marvel film not to have a post-credit scene?',
            answer: 'Avengers: Endgame'
        },
        {
            category: 'Marvel',
            question: 'What Top Gun character is Captain America’s cat named after?',
            answer: 'Goose'
        },
        {
            category: 'Marvel',
            question: 'Who replaced Terence Howard as War Machine in Iron Man 2 onwards?',
            answer: 'Don Cheadle'
        },
        {
            category: 'Marvel',
            question: 'What is the name of the organisation revealed to have taken over S.H.I.E.L.D. in Captain America: The Winter Soldier?',
            answer: 'Hydra'
        },
        {
            category: 'Marvel',
            question: 'Who killed Tony Stark’s parents?',
            answer: 'The Winter Soldier'
        },
        {
            category: 'Marvel',
            question: 'Agent Coulson went on to become the main character on which spin-off TV show?',
            answer: 'Marvel’s Agents of S.H.I.E.L.D.'
        },
        {
            category: 'Marvel',
            question: 'How man Infinity Stones are there?',
            answer: 'Six'
        },
        {
            category: 'Sci Fi',
            question: 'Which character speaks the first line in the original 1977 Star Wars movie?',
            answer: 'C-3PO'
        },
        {
            category: 'Sci Fi',
            question: 'Captain James Kirk is the most famous Captain of the USS Enterprise in Star Trek – but who had the job before him in the TV series?',
            answer: 'Captain Christopher Pike'
        },
        {
            category: 'Sci Fi',
            question: 'When Doctor Who came back to TV in 2005, it filmed under a secret codename. What was that codename?',
            answer: 'Torchwood'
        },
        {
            category: 'Sci Fi',
            question: 'Which animated cartoon scientist’s catchphrase is “Wubba lubba dub dub!”?',
            answer: 'Rick Sanchez, Rick & Morty'
        },
        {
            category: 'Sci Fi',
            question: 'Which of these four Marvel movie characters was NOT recast in a later film? Red Skull /General “Thunderbolt” Ross/ Bruce Banner, aka the Hulk/James Rhodes, aka War Machine',
            answer: 'General “Thunderbolt” Ross (played by William Hurt all the way through)'
        },
        {
            category: 'Sci Fi',
            question: 'What is the superhero alter-ego of the character Billy Batson?',
            answer: 'Shazam! (may accept Captain Marvel)'
        },
        {
            category: 'Sci Fi',
            question: 'In the BBC TV series Merlin, who played Merlin?',
            answer: 'Colin Morgan'
        },
        {
            category: 'Sci Fi',
            question: 'In Game of Thrones, what is the name of Westeros’ capital city?',
            answer: 'King’s Landing'
        },
        {
            category: 'Sci Fi',
            question: 'What was Hermione’s original surname in JK Rowling’s early notes for the Harry Potter books?',
            answer: 'Puckle, rather than Granger'
        },
        {
            category: 'Sci Fi',
            question: 'Name one of sci-fi author Isaac Asimov’s Three Rules of Robotics (as seen in the movie I, Robot.)',
            answer: 'First Law –A robot may not injure a human being or, through inaction, allow a human being to come to harm. Second Law – A robot must obey the orders given it by human beings except where such orders would conflict with the First Law. Third Law – A robot must protect its own existence as long as such protection does not conflict with the First or Second Laws.'
        },
        {
            category: 'Sci Fi',
            question: 'In what year did the Daleks first appear in Doctor Who?',
            answer: 1963
        },
        {
            category: 'Sci Fi',
            question: 'In the TV adaptation of the Witcher fantasy novels, what is the full name of Henry Cavill’s lead character?',
            answer: 'Geralt of Rivia'
        },
        {
            category: 'Sci Fi',
            question: 'Complete the following famous quote from the TV series Lost in Space: “Danger ____ _____.”',
            answer: 'Full quote: “Danger, Will Robinson.”'
        },
        {
            category: 'Sci Fi',
            question: 'Where were Peter Jackson’s Lord of the Rings movies filmed?',
            answer: 'New Zealand'
        },
        {
            category: 'Sci Fi',
            question: 'In 2001: A Space Odyssey, what song does the rogue computer system HAL 9000 sing to himself as he’s shut down?',
            answer: 'The nursery rhyme Daisy Bell (Bicycle built for Two)'
        },
        {
            category: 'Sci Fi',
            question: 'In the Batman comics, Dick Grayson was the first to act as the Caped Crusader’s sidekick Robin. Can you name one of the other fictional characters to take on the superhero identity?',
            answer: 'Jason Todd/Tim Drake/Stephanie Brown/Damian Wayne/Carrie Kelley'
        },
        {
            category: 'Sci Fi',
            question: 'How old was Jedi Master Yoda when he died in Star Wars: Return of the Jedi?',
            answer: 'Around 900 years old'
        },
        {
            category: 'Sci Fi',
            question: 'In the film Jurassic Park, what is the name of the island where park is located?',
            answer: 'Isla Nubar'
        },
        {
            category: 'Sci Fi',
            question: 'In the Alien movie series, what is the name of Ellen Ripley’s pet cat?',
            answer: 'Jonesy'
        },
        {
            category: 'Sci Fi',
            question: 'What exactly is Deep Space Nine in the television series Star Trek: Deep Space Nine?',
            answer: 'A space station'
        },
        {
            category: 'Harry Potter',
            question: 'Who is Harry Potter’s godfather?',
            answer: 'Sirius Black'
        },
        {
            category: 'Harry Potter',
            question: 'What year was the first film, Harry Potter and the Philosopher’s Stone, released in cinemas?',
            answer: 2001
        },
        {
            category: 'Harry Potter',
            question: 'Which future Batman actor played Triwizard Tournament entrant Cedric Diggory?',
            answer: 'Robert Pattinson'
        },
        {
            category: 'Harry Potter',
            question: 'Who was Minister for Magic during the first five films?',
            answer: 'Cornelius Fudge'
        },
        {
            category: 'Harry Potter',
            question: 'What was the name of the Hippogriff Harry and Hermione save from execution?',
            answer: 'Buckbeak'
        },
        {
            category: 'Harry Potter',
            question: 'On which floor of Hogwarts was the forbidden corridor in The Philosopher’s Stone?',
            answer: 'Third floor'
        },
        {
            category: 'Harry Potter',
            question: 'What was the name of the tree Harry and Ron crashed their car into?',
            answer: 'Whomping Willow'
        },
        {
            category: 'Harry Potter',
            question: 'Who did Killing Eve star Fiona Shaw play in the Harry Potter films?',
            answer: 'Petunia Dursley'
        },
        {
            category: 'Harry Potter',
            question: 'What village can Hogwarts students visit from their third year?',
            answer: 'Hogsmeade'
        },
        {
            category: 'Harry Potter',
            question: 'Who was Hermione’s date at the Yule Ball?',
            answer: 'Viktor Krum'
        },
        {
            category: 'Harry Potter',
            question: 'What is the name of Voldermort’s pet snake?',
            answer: 'Nagini'
        },
        {
            category: 'Harry Potter',
            question: 'What flavour are Bertie Bott’s beans?',
            answer: 'Every Flavour'
        },
        {
            category: 'Harry Potter',
            question: 'Which future Doctor Who played Barty Crouch Jr. in The Goblet of Fire?',
            answer: 'David Tennant'
        },
        {
            category: 'Harry Potter',
            question: 'What is the name of the building said to be the most haunted in Britain?',
            answer: 'Shrieking Shack'
        },
        {
            category: 'Harry Potter',
            question: 'What animal represents Hufflepuff house?',
            answer: 'Badger'
        },
        {
            category: 'Harry Potter',
            question: 'What is the name of Harry’s mother?',
            answer: 'Lily'
        },
        {
            category: 'Harry Potter',
            question: 'Which actor replaced Richard Harris as Dumbledore from the Prisoner of Azkaban onwards?',
            answer: 'Michael Gambon'
        },
        {
            category: 'Harry Potter',
            question: 'Which Weasley brother gets married in the Deathly Hallows Part 1?',
            answer: 'Bill Weasley'
        },
        {
            category: 'Harry Potter',
            question: 'Which class has a different teacher every year?',
            answer: 'Defence Against the Dark Arts'
        },
        {
            category: 'Harry Potter',
            question: 'Who did Game of Thrones actress Natalia Tena play in the Harry Potter films?',
            answer: 'Nymphadora Tonks'
        },
        {
            category: 'Disney',
            question: 'Who directed the 2019 Aladdin live-action reboot?',
            answer: 'Guy Ritchie'
        },
        {
            category: 'Disney',
            question: 'Which Broadway heavyweight provided the singing voices for Princess Jasmine in Aladdin (1992) and Mulan in both Mulan (1998) and Mulan II (2004)?',
            answer: 'Lea Salonga'
        },
        {
            category: 'Disney',
            question: 'What are the names of all 7 dwarves in Snow White (1937)?',
            answer: 'Doc, Grumpy, Happy, Sleepy, Bashful, Sneezy and Dopey'
        },
        {
            category: 'Disney',
            question: '“Pull the lever Kronk” is a line from which 2000 film?',
            answer: 'The Emperor’s New Groove'
        },
        {
            category: 'Disney',
            question: 'What is the name of Nick Parker’s girlfriend, played by Elaine Hendrix, in The Parent Trap (1998)?',
            answer: 'Meredith Blake'
        },
        {
            category: 'Disney',
            question: 'In The Princess Diaries, Mia Thermopolis is heir to the throne of which fictional European country?',
            answer: 'Genovia'
        },
        {
            category: 'Disney',
            question: 'How many Disney films has Lindsay Lohan appeared in?',
            answer: '6 – The Parent Trap, Life-Size, Get a Clue, Freaky Friday, Confessions of a Teenage Drama Queen, Herbie: Fully Loaded,'
        },
        {
            category: 'Disney',
            question: 'Who plays Cruella Deville in 1996’s 101 Dalmatians?',
            answer: 'Glenn Close'
        },
        {
            category: 'Disney',
            question: 'Which song from 2017 animated film Coco won the Academy Award for Best Original Song that year?',
            answer: 'Remember Me'
        },
        {
            category: 'Disney',
            question: 'In Finding Nemo, what is the address of the dentist office in which Nemo is kept?',
            answer: '42 Wallaby Way, Sydney, Australia'
        },
        {
            category: 'Disney',
            question: 'Who is the Disney director (Ratatouille, The Incredibles, The Incredible 2) was the voice of Edna Mode?',
            answer: 'Brad Bird'
        },
        {
            category: 'Disney',
            question: 'Who played the titular role in the 2015 Cinderella live-action reboot?',
            answer: 'Lily James'
        },
        {
            category: 'Disney',
            question: 'In Wreck It Ralph, what is the name of the game to which Vanellope von Schweetz belongs?',
            answer: 'Sugar Rush'
        },
        {
            category: 'Disney',
            question: 'When Will My Life Begin is a song from which Disney film?',
            answer: 'Tangled'
        },
        {
            category: 'Disney',
            question: 'In Beauty and the Beast (1991), which wing of the castle is Belle forbidden from visiting?',
            answer: 'West wing'
        },
        {
            category: 'Disney',
            question: 'Who wrote the music and lyrics for The Lion King’s musical numbers? (1994 Lion King)',
            answer: 'Elton John and Tim Rice'
        },
        {
            category: 'Disney',
            question: 'What are the names of Hades’s henchmen in Hercules (1997)?',
            answer: 'Pain and Panic'
        },
        {
            category: 'Disney',
            question: 'Who is the 2012 film Brave dedicated to?',
            answer: 'Steve Jobs, the Apple CEO and chairman of Pixar, who died before the film’s release.'
        },
        {
            category: 'Disney',
            question: 'What is Sully’s full name in Monsters Inc?',
            answer: 'James P. Sullivan'
        },
        {
            category: 'Disney',
            question: 'Which Arrested Development star voiced Nick Wilde in Zootopia (2016)?',
            answer: 'Jason Bateman'
        },
        {
            category: 'Disney',
            question: 'What year did High School Music premiere on the Disney Channel?',
            answer: 2006
        },
        {
            category: 'Disney',
            question: 'Who will play Ariel in the live-action Little Mermaid?',
            answer: 'Halle Bailey'
        },
        {
            category: 'Disney',
            question: 'Which US state is Lilo & Stitch set in?',
            answer: 'Hawaii'
        },
        {
            category: 'Disney',
            question: 'In which Disney animated film does Joaquin Phoenix voice the main character?',
            answer: 'Brother Bear – voice of Kenai'
        },
        {
            category: 'Disney',
            question: 'What year was Bambi released?',
            answer: 1942
        },
        {
            category: 'Disney',
            question: 'In Winnie-the-Pooh, what type of animal is Eeyore?',
            answer: 'Donkey'
        },
        {
            category: 'Disney',
            question: 'Where does Edgar the butler try to ship Duchess and her kittens to in The Aristocats?',
            answer: 'Timbuktu'
        },
        {
            category: 'Disney',
            question: 'What is the name of the dog in Up (2009)?',
            answer: 'Dug'
        },
        {
            category: 'Disney',
            question: 'Who voices Tiana in The Princess and the Frog?',
            answer: 'Anika Noni Rose'
        },
        {
            category: 'Disney',
            question: 'Who does Emma Thompson portray in Saving Mr Banks?',
            answer: 'P. L. Travers'
        },
        {
            category: 'James Bond',
            question: 'Which director helmed the most James Bond films?',
            answer: 'John Glen (Eight – For Your Eyes Only, Octopussy, A View to a Kill, The Living Daylights, Licence to Kill)'
        },
        {
            category: 'James Bond',
            question: 'What was the first Bond film *not* to take its title from an Ian Fleming novel or short story?',
            answer: 'Licence to Kill (1989)'
        },
        {
            category: 'James Bond',
            question: 'Which actor portrayed both Henderson in You Only Live Twice (1967) and Blofeld in Diamonds Are Forever (1971)?',
            answer: 'Charles Gray'
        },
        {
            category: 'James Bond',
            question: 'What is the first car that we see James Bond drive on-screen in the first movie Dr. No (1962)?',
            answer: 'Sunbeam Alpine'
        },
        {
            category: 'James Bond',
            question: 'Who played “Jimmy” Bond – an American version of 007 – in a US TV version of Casino Royale in 1954?',
            answer: 'Barry Nelson'
        },
        {
            category: 'James Bond',
            question: 'Christopher Lee played Bond villain Scaramanga in The Man With The Golden Gun (1974) – but what relation is he to Bond author Ian Fleming?',
            answer: 'Lee and Fleming were cousins'
        },
        {
            category: 'James Bond',
            question: 'Which 00 agent is murdered early on in Octopussy (1983)?',
            answer: '009'
        },
        {
            category: 'James Bond',
            question: 'What does the acronym “SPECTRE” stand for?',
            answer: 'SPecial Executive for Counter-intelligence, Terrorism, Revenge, Extortion.'
        },
        {
            category: 'James Bond',
            question: 'Which Bond film has won the most Academy Awards?',
            answer: 'Skyfall (it won five Oscars in 2012)'
        },
        {
            category: 'James Bond',
            question: 'What is the shortest James Bond film?',
            answer: 'Quantum of Solace (106 minutes)'
        },
        {
            category: 'James Bond',
            question: 'Which two Bond movies feature Robbie Coltrane as Valentin Zukovsky?',
            answer: 'Goldeneye (1995) and The World is Not Enough (1999)'
        },
        {
            category: 'James Bond',
            question: 'What was the first Bond title song to reach No. 1 in the UK’s Official Singles Chart?',
            answer: '‘Writing’s on the Wall’ by Sam Smith, from Spectre (2015)'
        },
        {
            category: 'James Bond',
            question: 'What is the name of Madonna’s character – a fencing instructor – that she plays in Die Another Day (2002)?',
            answer: 'Verity'
        },
        {
            category: 'James Bond',
            question: 'Who is the first character killed in a James Bond movie?',
            answer: 'Strangways (Timothy Moxon) in Dr. No (1962)'
        },
        {
            category: 'James Bond',
            question: 'Joe Don Baker – who later played CIA agent Jack Wade in Goldeneye (1995) and Tomorrow Never Dies (1997) – played which villainous character in The Living Daylights (1987)?',
            answer: 'Brad Whitaker'
        },
        {
            category: 'James Bond',
            question: 'Felix Leiter is maimed by a shark in the film Licence to Kill (1989) – but in which Ian Fleming novel does this sequence appear?',
            answer: 'Live and Let Die'
        },
        {
            category: 'James Bond',
            question: 'What is revealed to be Blofeld’s birth name in Spectre (2015)?',
            answer: 'Franz Oberhauser'
        },
        {
            category: 'James Bond',
            question: 'Who is the only Bond actor to drop to one knee during their opening gun-barrel sequence?',
            answer: 'George Lazenby'
        },
        {
            category: 'James Bond',
            question: 'How many actors have played M in the official James Bond film series?',
            answer: 'Four (Bernard Lee, Robert Brown, Judi Dench and Ralph Fiennes)'
        },
        {
            category: 'James Bond',
            question: 'What was the original planned title for Licence to Kill (1989)?',
            answer: 'Licence Revoked'
        },
        {
            category: 'History',
            question: 'As recently dramatised in a critically acclaimed miniseries, what year did the Chernobyl disaster occur?',
            answer: 1986
        },
        {
            category: 'History',
            question: 'Who was Lord Mayor of London four times between 1397 and 1419, and the inspiration for a classic English folk tale?',
            answer: 'Richard (Dick) Whittington'
        },
        {
            category: 'History',
            question: 'Who was the second President of the United States?',
            answer: 'John Adams'
        },
        {
            category: 'History',
            question: 'Who was the only one of Henry VIII’s wives to receive a Queen’s funeral?',
            answer: 'Jane Seymour'
        },
        {
            category: 'History',
            question: 'Which British archaeologist discovered Tutankhamun’s tomb?',
            answer: 'Howard Carter'
        },
        {
            category: 'History',
            question: 'Who was the leader of Britain’s ill-fated Antarctic expedition which was one of the first to reach the South Pole in 1912?',
            answer: 'Robert F. Scott'
        },
        {
            category: 'History',
            question: 'In which European country was there a civil war between 1946 and 1949?',
            answer: 'Greece'
        },
        {
            category: 'History',
            question: 'Which 13th Century Scottish knight did Mel Gibson portray in Braveheart?',
            answer: 'William Wallace'
        },
        {
            category: 'History',
            question: 'Which war was fought in South Africa between 1899 and 1902?',
            answer: 'Second Boer War (Allow Boer War)'
        },
        {
            category: 'History',
            question: 'In which country did the Second World War Battles of El Alamein take place?',
            answer: 'Egypt'
        },
        {
            category: 'History',
            question: 'Who discovered the wreckage of the Titanic?',
            answer: 'Robert Ballard'
        },
        {
            category: 'History',
            question: 'What year did the Challenger Space Shuttle disaster take place?',
            answer: 1986
        },
        {
            category: 'History',
            question: 'What year was the Magna Carta signed?',
            answer: 1215
        },
        {
            category: 'History',
            question: 'Which British King suffered from a stammer, as portrayed by Colin Firth in The King’s Speech?',
            answer: 'King George VI'
        },
        {
            category: 'History',
            question: 'What was the name of the ship that brought news of the Battle of Trafalgar to Britain?',
            answer: 'HMS Pickle'
        },
        {
            category: 'History',
            question: 'Which pilot famously fought in the Battle of Britain with two artificial legs?',
            answer: 'Douglas Bader'
        },
        {
            category: 'History',
            question: 'When did the Korean War end?',
            answer: 'It didn’t – an armistice was signed in 1953 but the two countries are still technically at war'
        },
        {
            category: 'History',
            question: 'Which real-life American frontiersman did Leonardo DiCaprio portray in The Revenant?',
            answer: 'Hugh Glass'
        },
        {
            category: 'History',
            question: 'Which ship is notorious for experiencing the bloodiest mutiny in British naval history? (Hint – it’s also the name of a Harry Potter character)',
            answer: 'HMS Hermione'
        },
        {
            category: 'History',
            question: 'Who discovered penicillin?',
            answer: 'Alexander Fleming'
        },
        {
            category: 'Football',
            question: 'Who was the last Manchester United player to win the Ballon d’Or before Cristiano Ronaldo?',
            answer: 'George Best'
        },
        {
            category: 'Football',
            question: 'Which current Premier League team has launched a bid to be officially recognised as the oldest professional club in the world?',
            answer: 'Crystal Palace'
        },
        {
            category: 'Football',
            question: 'Which player has made the most appearances in Premier League history?',
            answer: 'Gareth Barry (632)'
        },
        {
            category: 'Football',
            question: 'Which team has spent the most seasons in Serie A? (88)',
            answer: 'Inter'
        },
        {
            category: 'Football',
            question: 'Which Sheffield United striker scored the first goal ever in the Premier League in 1992/93?',
            answer: 'Brian Deane'
        },
        {
            category: 'Football',
            question: 'Name the top two teams in the English Championship prior to lockdown.',
            answer: 'Leeds, West Brom'
        },
        {
            category: 'Football',
            question: 'How many top division domestic titles had Chelsea won prior to their first triumph of the Roman Abramovich era in 2004/05?',
            answer: 'One'
        },
        {
            category: 'Football',
            question: 'Which former Premier League team was sponsored by TY – owners of the Beanie Babies franchise – between 2002 and 2005?',
            answer: 'Portsmouth'
        },
        {
            category: 'Football',
            question: 'Alan Shearer is the all-time top Premier League goalscorer – how many did he score?',
            answer: 260
        },
        {
            category: 'Football',
            question: 'Which goalkeeper kept the most clean sheets in Premier League history?',
            answer: 'Petr Cech (202)'
        },
        {
            category: 'Football',
            question: 'One side in 2018/19 became the first team to not draw a single game away from home in the Premier League. Name the team.',
            answer: 'Tottenham'
        },
        {
            category: 'Football',
            question: 'Has Zlatan Ibrahimovic won the Champions League in his career?',
            answer: 'No'
        },
        {
            category: 'Football',
            question: 'What is so significant about Mario Balotelli’s only assist for Manchester City in the Premier League?',
            answer: 'Set up Sergio Aguero to win the Premier League v QPR'
        },
        {
            category: 'Football',
            question: 'Which team plays their football at Estadio da Luz?',
            answer: 'Benfica'
        },
        {
            category: 'Football',
            question: 'With 170 caps to her name, which player has made the most appearances for the England women’s national team?',
            answer: 'Fara Williams'
        },
        {
            category: 'Football',
            question: 'Brazil have won the World Cup more times than any other team with five triumphs. Which two nations are joint-second with four World Cup titles?',
            answer: 'Italy and Germany'
        },
        {
            category: 'Football',
            question: 'Which British team has won the European Cup more times than its own domestic top league?',
            answer: 'Nottingham Forest'
        },
        {
            category: 'Football',
            question: 'Fans of Dutch side ADO Den Haag stormed the club’s training ground in February 2020 to confront their manager – a British former Premier League boss – over his poor tactics. Name the manager.',
            answer: 'Alan Pardew'
        },
        {
            category: 'Football',
            question: 'Name the only ever English winner of the European Golden Boot.',
            answer: 'Kevin Phillips (Sunderland – 30 goals in 1999/2000)'
        },
        {
            category: 'Football',
            question: 'How many West Ham players were named in the England starting XI for the 1966 World Cup final?',
            answer: 'Three (Bobby Moore, Martin Peters, Geoff Hurst)'
        },
        {
            category: 'Football',
            question: 'Who is the only player to win the Champions League with three different clubs?',
            answer: 'Clarence Seedorf (Ajax, Real Madrid, AC Milan)'
        },
        {
            category: 'Football',
            question: 'Which player was sold for the highest transfer fee ever received by a Premier League team?',
            answer: 'Philippe Coutinho (£105m – Liverpool to Barcelona)'
        },
        {
            category: 'Football',
            question: 'Who is Manchester City’s record signing?',
            answer: 'Rodri'
        },
        {
            category: 'Football',
            question: 'In 1986, defender Alvin Martin scored a hat-trick in an 8-1 victory over Newcastle. What was so peculiar about his achievement?',
            answer: 'He scored against three different goalkeepers'
        },
        {
            category: 'Football',
            question: 'How many Ballon d’Or awards has Lionel Messi won?',
            answer: 'Six'
        },
        {
            category: 'Football',
            question: 'Which Premier League or EFL team is known as: The Chairboys',
            answer: 'Wycombe Wanderers'
        },
        {
            category: 'Football',
            question: 'Which Premier League or EFL team is known as: The Cod Army',
            answer: 'Fleetwood Town'
        },
        {
            category: 'Football',
            question: 'Which Premier League or EFL team is known as: The Hornets',
            answer: 'Watford'
        },
        {
            category: 'Football',
            question: 'Which Premier League or EFL team is known as: The Railwaymen',
            answer: 'Crewe Alexandra'
        },
        {
            category: 'Football',
            question: 'Which Premier League or EFL team is known as: The Valiants',
            answer: 'Port Vale'
        },
        {
            category: 'Cricket',
            question: 'The Big Bash League is based in which country?',
            answer: 'Australia'
        },
        {
            category: 'Cricket',
            question: 'Ben Stokes made his Test debut for England in which year?',
            answer: 2013
        },
        {
            category: 'Cricket',
            question: 'Who was the first player to reach 10,000 runs in Test cricket?',
            answer: 'Sunil Gavaskar'
        },
        {
            category: 'Cricket',
            question: 'Which ground is commonly referred to as the Home of Cricket?',
            answer: 'Lord’s'
        },
        {
            category: 'Cricket',
            question: 'Which first-class county club did Phil Tuffnel play for?',
            answer: 'Middlesex'
        },
        {
            category: 'Cricket',
            question: 'Which player racked up the highest score in a single innings during the 2019 Cricket World Cup? (166)',
            answer: 'David Warner'
        },
        {
            category: 'Cricket',
            question: 'Who has recorded the most career runs in England Test match cricket?',
            answer: 'Alastair Cook'
        },
        {
            category: 'Cricket',
            question: 'Name the three England players to have captained the Twenty20 International side more than 10 times.',
            answer: 'Paul Collingwood, Stuart Broad, Eoin Morgan'
        },
        {
            category: 'Cricket',
            question: 'Who has won more series’ of The Ashes – England or Australia?',
            answer: 'Australia'
        },
        {
            category: 'Cricket',
            question: 'How many runs did Jack Leach score during his iconic second innings with Ben Stokes during the 2019 Ashes Third Test at Headingley?',
            answer: 1
        },
        {
            category: 'Cricket',
            question: 'The first official international cricket match was held in 1844 between which two nations?',
            answer: 'USA and Canada'
        },
        {
            category: 'Cricket',
            question: 'What is the Duckworth-Lewis-Stern method used for in cricket?',
            answer: 'To determine a target score in case a limited overs match is abandoned due to rain or other circumstances.'
        },
        {
            category: 'Cricket',
            question: 'A cricket umpire raises both of their arms straight above their head – what does this signify?',
            answer: 'The batsman has scored a six'
        },
        {
            category: 'Cricket',
            question: 'What is the term used when a player is bowled out by the first ball they face?',
            answer: 'Golden duck'
        },
        {
            category: 'Cricket',
            question: 'Jofra Archer will play for which team in The Hundred?',
            answer: 'Southern Brave'
        },
        {
            category: 'Cricket',
            question: 'How many days did the longest recorded Test match last?',
            answer: 'Nine days (England v South Africa, 1939)'
        },
        {
            category: 'Cricket',
            question: 'Who is the only batsman to record 400 runs in an international Test match?',
            answer: 'Brian Lara (v England in 2004)'
        },
        {
            category: 'Cricket',
            question: 'Which player took the most wickets in the 2019 Cricket World Cup?',
            answer: 'Mitchell Starc'
        },
        {
            category: 'Cricket',
            question: 'Nasser Hussain last captained the England Test team in which year?',
            answer: 2003
        },
        {
            category: 'Cricket',
            question: 'Eoin Morgan has played more ODI matches for Ireland than he has played Test matches for England – true or false?',
            answer: 'True'
        },
        {
            category: 'Tennis',
            question: 'Which two players met in three consecutive Wimbledon finals in 1988, 1989 and 1990?',
            answer: 'Boris Becker, Stefan Edberg'
        },
        {
            category: 'Tennis',
            question: 'Nicolas Mahut and John Isner recorded the longest tennis match in history at Wimbledon in 2010. How many games were played in total during the final set?',
            answer: 138
        },
        {
            category: 'Tennis',
            question: 'How many times has Rafael Nadal won the French Open?',
            answer: 12
        },
        {
            category: 'Tennis',
            question: 'Roger Federer was born in which Swiss city?',
            answer: 'Basel'
        },
        {
            category: 'Tennis',
            question: 'How many Olympic gold medals has Andy Murray won?',
            answer: 'Two (2012, 2016)'
        },
        {
            category: 'Tennis',
            question: 'Who was the first person to win Wimbledon with an invitation?',
            answer: 'Goran Ivanisevic'
        },
        {
            category: 'Tennis',
            question: 'How many Grand Slam singles titles has Serena Williams won?',
            answer: 23
        },
        {
            category: 'Tennis',
            question: 'In which year was Roger Federer and Rafael Nadal’s epic Wimbledon final that ended at 9:15pm in the dark, a year before the Centre Court roof was installed?',
            answer: 2008
        },
        {
            category: 'Tennis',
            question: 'Who is the current world Number 1 in the Women’s game (WTA rankings)?',
            answer: 'Ashleigh Barty'
        },
        {
            category: 'Tennis',
            question: 'Who is the current world Number 1 in the Men’s game (ATP rankings)?',
            answer: 'Novak Djokovic'
        },
        {
            category: 'Tennis',
            question: 'Who is the last player to win back-to-back Women’s singles Grand Slam titles?',
            answer: 'Naomi Osaka'
        },
        {
            category: 'Tennis',
            question: 'In which year did Serena Williams win her last Grand Slam singles title?',
            answer: 2015
        },
        {
            category: 'Tennis',
            question: 'Who did Roger Federer beat in the final to claim his first Wimbledon title in 2003?',
            answer: 'Mark Philippoussis'
        },
        {
            category: 'Tennis',
            question: 'Which tennis superstar is the only player to achieve a Golden Slam? (Winning all four Grand Slams and an Olympic gold in the same calendar year)',
            answer: 'Steffi Graf'
        },
        {
            category: 'Tennis',
            question: 'Fred Perry was the last British man to win Wimbledon before Andy Murray. Which year was Perry’s last triumph?',
            answer: 1936
        },
        {
            category: 'Tennis',
            question: 'Serena and Venus Williams played each other in four consecutive Grand Slam finals between 2002 and 2003. How many of those matches did Venus win?',
            answer: 'None'
        },
        {
            category: 'Tennis',
            question: 'How many times has Andy Murray won the Davis Cup?',
            answer: 'One (2015)'
        },
        {
            category: 'Tennis',
            question: 'Who is the last British woman to win a Grand Slam?',
            answer: 'Virginia Wade'
        },
        {
            category: 'Tennis',
            question: 'What is the official capacity of Centre Court? (to the nearest thousand)',
            answer: '14,979 (accept 15,000)'
        },
        {
            category: 'Tennis',
            question: 'Nick Kyrgios’ highest ATP tennis ranking is in the top 10 – true or false?',
            answer: 'False – his highest ranking was No. 13 in 2016'
        },
        {
            category: 'Golf',
            question: 'Which year was golf’s last appearance at the Olympic Games before returning to the line-up of sports in 2016?',
            answer: 1904
        },
        {
            category: 'Golf',
            question: 'Who was top of the golf world rankings prior to lockdown?',
            answer: 'Rory McIlroy'
        },
        {
            category: 'Golf',
            question: 'How many times has Tiger Woods won The Masters?',
            answer: 'Five'
        },
        {
            category: 'Golf',
            question: 'Which year did Brooks Koepka win his first major?',
            answer: '2017 (US Open)'
        },
        {
            category: 'Golf',
            question: 'Who won The Match: Tiger Woods v Phil Mickelson in 2018?',
            answer: 'Phil Mickelson'
        },
        {
            category: 'Golf',
            question: 'Name the only non-American player to win a major in 2019?',
            answer: 'Shane Lowry (The Open Championship)'
        },
        {
            category: 'Golf',
            question: 'In dollars, what is the total prize money purse available in The Players Championship – the most lucrative event on the tour?',
            answer: '$12,500,000'
        },
        {
            category: 'Golf',
            question: 'Which major has Rory McIlroy not won?',
            answer: 'The Masters'
        },
        {
            category: 'Golf',
            question: 'Who became the first European player to score what is now the maximum of five points in a single Ryder Cup in 2018?',
            answer: 'Francisco Molinari'
        },
        {
            category: 'Golf',
            question: 'Which golfer recorded the most points for USA in the 2019 Ryder Cup?',
            answer: 'Justin Thomas (4)'
        },
        {
            category: 'Golf',
            question: 'Who stunned the golf world by winning the 2003 PGA Championship despite being ranked No. 169th in the world at the time?',
            answer: 'Shaun Micheel'
        },
        {
            category: 'Golf',
            question: 'Tiger Woods was ranked No. __ in the world when he won The Masters in 2019…',
            answer: 'No. 12'
        },
        {
            category: 'Golf',
            question: 'Which course has been used more often than any other for The Open Championship? (29 times)',
            answer: 'St Andrews'
        },
        {
            category: 'Golf',
            question: 'Which player has won the most major championships in his career? (18)',
            answer: 'Jack Nicklaus'
        },
        {
            category: 'Golf',
            question: 'The 11th, 12th and 13th holes at Augusta National are collectively known by what nickname?',
            answer: 'Amen Corner'
        },
        {
            category: 'Golf',
            question: 'What was the last PGA Tour event to be played in full before the coronavirus lockdown?',
            answer: 'Arnold Palmer Invitational'
        },
        {
            category: 'Golf',
            question: 'Royal St George’s Golf Club is based in which English town that shares its name with a popular lunchtime food?',
            answer: 'Sandwich'
        },
        {
            category: 'Golf',
            question: 'In which country will the 2022 Ryder Cup be held?',
            answer: 'Italy'
        },
        {
            category: 'Golf',
            question: 'Tiger Woods was born in which US state?',
            answer: 'California'
        },
        {
            category: 'Golf',
            question: 'Who racked up a stunning 18 wins on Tour in 1945?',
            answer: 'Byron Nelson'
        },
        {
            category: 'Boxing',
            question: 'What boxing weight class is heaviest – flyweight, bantam weight, feather weight?',
            answer: 'Feather weight'
        },
        {
            category: 'Boxing',
            question: 'How old was George Foreman when he became the oldest heavyweight champion?',
            answer: 45
        },
        {
            category: 'Boxing',
            question: 'Which boxing promoter’s favourite expression is “Only in America”?',
            answer: 'Don King'
        },
        {
            category: 'Boxing',
            question: 'Tyson Fury has won an Olympic medal – true or false?',
            answer: 'False'
        },
        {
            category: 'Boxing',
            question: 'American boxer James J. Braddock was given which nickname inspired by a popular fairytale story?',
            answer: 'Cinderella Man'
        },
        {
            category: 'Boxing',
            question: 'In which round did Anthony Joshua defeat Wladimir Klitschko in their heavyweight showdown in 2017?',
            answer: '11th'
        },
        {
            category: 'Boxing',
            question: 'Who did Deontay Wilder defeat in November 2019 – his last victory before his rematch with Tyson Fury?',
            answer: 'Luis Ortiz'
        },
        {
            category: 'Boxing',
            question: 'How many times has Anthony Joshua fought outside of the UK?',
            answer: 'Twice (Saudi Arabia, USA)'
        },
        {
            category: 'Boxing',
            question: 'In which year was Floyd Mayweather’s last professional fight prior to his comeback showdown with Conor McGregor?',
            answer: 2015
        },
        {
            category: 'Boxing',
            question: 'Lennox Lewis was defeated by Hasim Rahman in 2001. What excuse did his promoter give for his lack of focus?',
            answer: 'He was filming Ocean’s Eleven at the time'
        },
        {
            category: 'Boxing',
            question: 'Which fighters starred in the 1971 bout that would later be known as ‘Fight of the Century’?',
            answer: 'Muhammad Ali, Joe Frazier'
        },
        {
            category: 'Boxing',
            question: 'Which fighter starred as “Pretty” Ricky Conlan in Rocky spin-off film Creed?',
            answer: 'Tony Bellew'
        },
        {
            category: 'Boxing',
            question: 'Who was David Haye’s last opponent in 2012 before taking a four-year break from the sport?',
            answer: 'Dereck Chisora'
        },
        {
            category: 'Boxing',
            question: 'Which super-middleweight fighter retired in 2008 with 46 wins, no draws, no defeats?',
            answer: 'Joe Calzaghe'
        },
        {
            category: 'Boxing',
            question: 'Which fighter knocked out Muhammad Ali in controversial circumstances towards the end of his career? (Ali was suffering from early symptoms of Parkinson’s disease at the time)',
            answer: 'Larry Holmes'
        },
        {
            category: 'Boxing',
            question: 'What is the northern-most UK city Anthony Joshua has fought in?',
            answer: 'Glasgow'
        },
        {
            category: 'Film anagram',
            question: 'In Attic',
            answer: 'Titanic'
        },
        {
            category: 'Film anagram',
            question: '2 Deal 8 Trays',
            answer: '28 Days Later'
        },
        {
            category: 'Film anagram',
            question: 'Chopys',
            answer: 'Psycho'
        },
        {
            category: 'Film anagram',
            question: 'Corky',
            answer: 'Rocky'
        },
        {
            category: 'Film anagram',
            question: 'He that forged',
            answer: 'The Godfather'
        },
        {
            category: 'Film anagram',
            question: 'That Deep Red',
            answer: 'The Departed'
        },
        {
            category: 'Film anagram',
            question: 'Ham Is Thiner',
            answer: 'The Irishman'
        },
        {
            category: 'Film anagram',
            question: 'Wheat Odor Fizz',
            answer: 'The Wizard of Oz'
        },
        {
            category: 'Film anagram',
            question: 'Cub Flight',
            answer: 'Fight Club'
        },
        {
            category: 'Film anagram',
            question: 'Respect',
            answer: 'Spectre'
        },
        {
            category: 'Film anagram',
            question: 'OK Nile Thing',
            answer: 'The Lion King'
        },
        {
            category: 'Film anagram',
            question: 'Votes in UK',
            answer: 'Knives Out'
        },
        {
            category: 'Film anagram',
            question: 'Damp Siren',
            answer: 'Spider-Man'
        },
        {
            category: 'Film anagram',
            question: 'His Drill Scents',
            answer: 'Schindler’s List'
        },
        {
            category: 'Film anagram',
            question: 'Mat Ban',
            answer: 'Batman'
        },
        {
            category: 'Film anagram',
            question: 'El Law',
            answer: 'Wall E'
        },
        {
            category: 'Film anagram',
            question: 'God Ol Leafs',
            answer: 'Goodfellas'
        },
        {
            category: 'Film anagram',
            question: 'The Carb Plank',
            answer: 'Black Panther'
        },
        {
            category: 'Film anagram',
            question: 'Pair Jars Suck',
            answer: 'Jurassic Park'
        },
        {
            category: 'Film anagram',
            question: 'Rum Pet Frogs',
            answer: 'Forrest Gump'
        },
        {
            category: 'Film anagram',
            question: 'Not Pug',
            answer: 'Top Gun'
        },
        {
            category: 'Film anagram',
            question: 'Wheel Loan',
            answer: 'Halloween'
        },
        {
            category: 'Film anagram',
            question: 'Throat Perry',
            answer: 'Harry Potter'
        },
        {
            category: 'Film anagram',
            question: 'Gather Seven',
            answer: 'The Avengers'
        },
        {
            category: 'Film anagram',
            question: 'React Monk',
            answer: 'Rocketman'
        },
        {
            category: 'Film anagram',
            question: 'Heights Inn',
            answer: 'The Shining'
        },
        {
            category: 'Film anagram',
            question: 'Stroll',
            answer: 'Trolls'
        },
        {
            category: 'Film anagram',
            question: 'Regards Cotton',
            answer: 'Doctor Strange'
        },
        {
            category: 'Film anagram',
            question: 'Squid Dice USA',
            answer: 'Suicide Squad'
        },
        {
            category: 'Film anagram',
            question: 'War Nod',
            answer: 'Onward'
        },
        {
            category: 'Only Fools and Horses',
            question: 'Uncle Albert famously took part in the Second World War – but was he in the Navy, the Air Force or the Army?',
            answer: 'Navy'
        },
        {
            category: 'Only Fools and Horses',
            question: 'What was the name of the Boycie’s flirtatious wife?',
            answer: 'Marlene'
        },
        {
            category: 'Only Fools and Horses',
            question: 'Which veteran sitcom writer created the series?',
            answer: 'John Sullivan'
        },
        {
            category: 'Only Fools and Horses',
            question: 'What does it say on the side of their iconic Robin Reliant?',
            answer: 'Trotters Independent Trading Co – New York – Paris – Peckham'
        },
        {
            category: 'Only Fools and Horses',
            question: 'Who was the only main cast member to return for prequel series Rock & Chips?',
            answer: 'Nicholas Lyndhurst'
        },
        {
            category: 'Only Fools and Horses',
            question: 'Which football legend joined the cast for a Sport Relief special in 2014?',
            answer: 'David Beckham'
        },
        {
            category: 'Only Fools and Horses',
            question: 'Which (incorrect) name does Trigger call Rodney?',
            answer: 'Dave'
        },
        {
            category: 'Only Fools and Horses',
            question: 'What is the name of the tower block the Trotters live in?',
            answer: 'Nelson Mandela House'
        },
        {
            category: 'Only Fools and Horses',
            question: 'What was the name of Del Boy and Raquel’s son?',
            answer: 'Damien'
        },
        {
            category: 'Only Fools and Horses',
            question: 'Who sang the iconic theme tune?',
            answer: 'John Sullivan'
        },
        {
            category: 'Only Fools and Horses',
            question: 'The episode Time on Our Hands holds the record for the highest UK audience for a sitcom – how many viewers did the episode attract?',
            answer: '24.3million'
        },
        {
            category: 'Only Fools and Horses',
            question: 'What is the age difference between Del Boy and Rodney?',
            answer: '12-13 years'
        },
        {
            category: 'Only Fools and Horses',
            question: 'What item finally makes the Trotters millionaires?',
            answer: 'Pocket watch'
        },
        {
            category: 'Only Fools and Horses',
            question: 'What does Raquel secretly work as when she first meets Del Boy?',
            answer: 'Stripper'
        },
        {
            category: 'Only Fools and Horses',
            question: 'How many heads and handles does Trigger claim that his “same” broom has had?',
            answer: '17 heads and 14 handles'
        },
        {
            category: 'Only Fools and Horses',
            question: 'What airport was the satellite dish stolen from in The Sky’s The Limit?',
            answer: 'Gatwick Airport'
        },
        {
            category: 'Only Fools and Horses',
            question: 'How high does Cassandra say Damien was on the diving board in If They Could See Us Now?',
            answer: '4 metres'
        },
        {
            category: 'Only Fools and Horses',
            question: 'What year did Only Fools and Horses The Musical premiere in the West End?',
            answer: 2019
        },
        {
            category: 'Only Fools and Horses',
            question: 'What 1982 episode featured the famous chandelier incident?',
            answer: 'A Touch of Glass'
        },
        {
            category: 'Only Fools and Horses',
            question: 'Complete this phrase: “Next time this year we’ll be….”',
            answer: 'Millionaires'
        },
        {
            category: 'Art',
            question: 'Who painted The Scream in 1893?',
            answer: 'Edvard Munch'
        },
        {
            category: 'Art',
            question: 'The 1930 Grant Wood painting ‘American Gothic’ depicts a woman and a man holding what?',
            answer: 'A pitchfork'
        },
        {
            category: 'Art',
            question: 'Name the artist that painted The Creation of Adam – the painting found on the Sistine Chapel’s ceiling.',
            answer: 'Michelangelo'
        },
        {
            category: 'Art',
            question: 'Where is the Mona Lisa displayed?',
            answer: 'The Louvre Museum, France'
        },
        {
            category: 'Art',
            question: 'Rene Magritte’s ‘The Son of Man’ depicts a man in a suit and bowler hat. Which fruit is covering his face?',
            answer: 'Apple'
        },
        {
            category: 'Art',
            question: 'Which brand of soup features in Andy Warhol’s soup can series?',
            answer: 'Campbell'
        },
        {
            category: 'Art',
            question: 'A bronze bust of a Portugese footballer was mocked online after its reveal in 2017 – who was the statue meant to resemble?',
            answer: 'Cristiano Ronaldo'
        },
        {
            category: 'Art',
            question: 'What was the name of Banksy’s 2015 pop-up exhibition in Weston-super-Mare?',
            answer: 'Dismaland'
        },
        {
            category: 'Art',
            question: 'Which Charlie’s Angel star is also an artist, whose paintings have been shown at the Lincoln Centre in New York?',
            answer: 'Lucy Liu'
        },
        {
            category: 'Art',
            question: 'Which British artist is known for an exhibit which featured a tiger shark preserved in formaldehyde?',
            answer: 'Damien Hirst'
        },
        {
            category: 'Art',
            question: 'Lucian Freud painted a naked portrait of which English supermodel in 2002?',
            answer: 'Kate Moss'
        },
        {
            category: 'Art',
            question: 'The Starry Night is a painting by which artist?',
            answer: 'Vincent van Gogh'
        },
        {
            category: 'Art',
            question: 'Andrew Wyeth’s 1948 painting ‘Christina’s World’ is of a real house in which US state?',
            answer: 'Maine'
        },
        {
            category: 'Art',
            question: 'Artist Richard Hamilton attended evening classes at which London art university?',
            answer: 'Central Saint Martins College of Arts and Design (known as Saint Martin’s School of Art at the time)'
        },
        {
            category: 'Art',
            question: 'Which American artist created the blue and red Barack Obama “Hope” poster?',
            answer: 'Shepard Fairey'
        },
        {
            category: 'Art',
            question: 'Where was Frida Kahlo born?',
            answer: 'Mexico'
        },
        {
            category: 'Art',
            question: 'What are the names of all four Tate galleries?',
            answer: 'Tate Britain, Tate Modern, Tate Liverpool and Tate St Ives.'
        },
        {
            category: 'Art',
            question: 'Who painted The Birth of Venus?',
            answer: 'Sandro Botticelli'
        },
        {
            category: 'Capital cities',
            question: 'What is the capital of Australia?',
            answer: 'Canberra'
        },
        {
            category: 'Capital cities',
            question: 'What is the capital of Brazil?',
            answer: 'Brasilia'
        },
        {
            category: 'Capital cities',
            question: 'What is the capital of Burkina Faso?',
            answer: 'Ouagadougou'
        },
        {
            category: 'Capital cities',
            question: 'What is the capital of the Bahamas?',
            answer: 'Nassau'
        },
        {
            category: 'Capital cities',
            question: 'What is the capital of Belarus?',
            answer: 'Minsk'
        },
        {
            category: 'Capital cities',
            question: 'What is the capital of Columbia?',
            answer: 'Bogota'
        },
        {
            category: 'Capital cities',
            question: 'What is the capital of Croatia?',
            answer: 'Zagreb'
        },
        {
            category: 'Capital cities',
            question: 'What is the capital of Cuba?',
            answer: 'Havana'
        },
        {
            category: 'Capital cities',
            question: 'What is the capital of Ethiopia?',
            answer: 'Addis Ababa'
        },
        {
            category: 'Capital cities',
            question: 'What is the capital of Canada?',
            answer: 'Ottawa'
        },
        {
            category: 'Capital cities',
            question: 'What is the capital of Bosnia and Herzegovina?',
            answer: 'Sarajevo'
        },
        {
            category: 'Capital cities',
            question: 'What is the capital of Bulgaria?',
            answer: 'Sofia'
        },
        {
            category: 'Capital cities',
            question: 'What is the capital of Chile?',
            answer: 'Santiago'
        },
        {
            category: 'Capital cities',
            question: 'What is the capital of El Salvador?',
            answer: 'San Salvador'
        },
        {
            category: 'Capital cities',
            question: 'What is the capital of Haiti?',
            answer: 'Port au Prince'
        },
        {
            category: 'Capital cities',
            question: 'What is the capital of Hungary?',
            answer: 'Budapest'
        },
        {
            category: 'Capital cities',
            question: 'What is the capital of Iceland?',
            answer: 'Reykjavik'
        },
        {
            category: 'Capital cities',
            question: 'What is the capital of India?',
            answer: 'New Delhi'
        },
        {
            category: 'Capital cities',
            question: 'What is the capital of Indonesia?',
            answer: 'Jakarta'
        },
        {
            category: 'Capital cities',
            question: 'What is the capital of Iran?',
            answer: 'Tehran'
        },
        {
            category: 'True or False',
            question: 'Cyclones spin in a clockwise direction in the southern hemisphere',
            answer: 'True'
        },
        {
            category: 'True or False',
            question: 'Goldfish only have a memory of three seconds',
            answer: 'False – scientists have found their memories can actually last for months'
        },
        {
            category: 'True or False',
            question: 'The capital of Libya is Benghazi',
            answer: 'False – it’s Tripoli'
        },
        {
            category: 'True or False',
            question: 'Brazil is the only country in the Americas to have the official language of Portuguese',
            answer: 'True'
        },
        {
            category: 'True or False',
            question: 'The Channel Tunnel is the longest rail tunnel in the world',
            answer: 'False – The Gotthard Base Tunnel in Switzerland is 4 miles longer at 35.5 miles long'
        },
        {
            category: 'True or False',
            question: 'Darth Vader famously says the line “Luke, I am your father” in The Empire Strikes Back',
            answer: 'False – the line is actually “No, I am your father”'
        },
        {
            category: 'True or False',
            question: 'Olivia Newton-John represented the UK in the Eurovision Song Contest in 1974, the year ABBA won with “Waterloo”',
            answer: 'True'
        },
        {
            category: 'True or False',
            question: 'Stephen Hawking declined a knighthood from the Queen',
            answer: 'True'
        },
        {
            category: 'True or False',
            question: 'The highest mountain in England is Ben Nevis',
            answer: 'False – Ben Nevis is in Scotland'
        },
        {
            category: 'True or False',
            question: 'Nicolas Cage and Michael Jackson both married the same woman',
            answer: 'True – to Lisa Marie Presley no less'
        },
        {
            category: 'True or False',
            question: 'Japan and Russia did not sign a peace treaty after World War Two so are technically still at war',
            answer: 'True – the two countries have signed a joint declaration but not a peace treaty'
        },
        {
            category: 'True or False',
            question: 'The mathematical name for the shape of a Pringle is hyperbolic paraboloid',
            answer: 'True'
        },
        {
            category: 'True or False',
            question: 'Charlie Chaplin came first in a Charlie Chaplin look-alike contest',
            answer: 'False – he came third'
        },
        {
            category: 'True or False',
            question: 'Michael Keaton’s real name is Michael Douglas',
            answer: 'True'
        },
        {
            category: 'True or False',
            question: 'Napoleon was of below-average height',
            answer: 'False – at 5 ft 7 in he was slightly above average height for the time'
        },
        {
            category: 'True or False',
            question: 'Donald Duck’s middle name is Fauntelroy',
            answer: 'True'
        },
        {
            category: 'True or False',
            question: 'The Statue of Liberty was a gift from France',
            answer: 'True'
        },
        {
            category: 'True or False',
            question: 'According to Scottish law, it is illegal to be drunk in charge of a cow',
            answer: 'True'
        },
        {
            category: 'True or False',
            question: 'The Great Wall of China is visible from space',
            answer: 'False'
        },
        {
            category: 'True or False',
            question: 'The first tea bags were made of silk',
            answer: 'True'
        },
        {
            category: 'True or False',
            question: 'Meghan Markle’s first name is Rachel',
            answer: 'True'
        },
        {
            category: 'True or False',
            question: 'Warsaw is the capital of Bulgaria',
            answer: 'False'
        },
        {
            category: 'True or False',
            question: 'A metre is further than a yard',
            answer: 'True'
        },
        {
            category: 'True or False',
            question: 'A woman has walked on the moon',
            answer: 'False'
        },
        {
            category: 'True or False',
            question: 'Flying in an aeroplane is statistically safer than driving in a car',
            answer: 'True'
        },
        {
            category: 'The Crown',
            question: 'For how many years was the real-life Winston Churchill prime minister for? A bonus point if you can name the actor who played him in The Crown.',
            answer: 'Nine years. Bonus point answer: John Lithgow.'
        },
        {
            category: 'The Crown',
            question: 'Which actress is set to play Queen Elizabeth II in The Crown series five, succeeding Olivia Colman?',
            answer: 'Imelda Staunton.'
        },
        {
            category: 'The Crown',
            question: 'In what year did The Crown’s first season, starring Claire Foy, first premiere on Netflix?',
            answer: 'November 2016.'
        },
        {
            category: 'The Crown',
            question: 'What was the real-life family nickname of King George VI, father to Queen Elizabeth II?',
            answer: 'Bertie.'
        },
        {
            category: 'The Crown',
            question: 'To the nearest million, how many people in Britain watched the real-life coronation of Queen Elizabeth II?',
            answer: '27 million.'
        },
        {
            category: 'The Crown',
            question: 'What was the profession of Galina Ulanova, the woman whose photograph the Queen discovers hidden in her husband Philip’s briefcase during The Crown?',
            answer: 'Ballerina.'
        },
        {
            category: 'The Crown',
            question: 'What annual custom did the Queen adopt after Lord Altrincham urged her to ‘modernise’ the monarchy in the late 1950s?',
            answer: 'The Christmas address.'
        },
        {
            category: 'The Crown',
            question: 'Where did the Duke and Duchess of Windsor visit in 1937, later causing controversy for the royal family?',
            answer: 'Berlin.'
        },
        {
            category: 'The Crown',
            question: 'Name the boarding school where both Prince Philip and later Prince Charles attended.',
            answer: 'Gordonstoun.'
        },
        {
            category: 'The Crown',
            question: 'Name the real-life model who had an affair with the Secretary of State for War, John Profumo.',
            answer: 'Christine Keeler.'
        },
        {
            category: 'The Crown',
            question: 'In which African country did the Queen famously dance the foxtrot in 1961?',
            answer: 'Ghana.'
        },
        {
            category: 'The Crown',
            question: 'What year was Prince Edward, the youngest of Queen Elizabeth II’s four children, born?',
            answer: '1964.'
        },
        {
            category: 'The Crown',
            question: 'Name all the actors who have played Antony Armstrong-Jones, Earl of Snowdon, over the past three seasons of The Crown.',
            answer: 'Matthew Goode (season 2) and Ben Daniels (season 3).'
        },
        {
            category: 'The Crown',
            question: 'In series three, Queen Elizabeth II visits Winston Churchill on his deathbed. Was this historically accurate?',
            answer: 'No.'
        },
        {
            category: 'The Crown',
            question: 'The Crown series three has been criticised for not featuring Princess Anne’s wedding. What was the name of the man she married in 1973?',
            answer: 'Captain Mark Phillips.'
        },
        {
            category: 'The Crown',
            question: 'Name the British actress who played Clementine Churchill in The Crown series one.',
            answer: 'Harriet Walter.'
        },
        {
            category: 'The Crown',
            question: 'Olivia Colman, who currently plays Queen Elizabeth II, won an Oscar for playing another royal. Name the royal in question.',
            answer: 'Anne, Queen of Great Britain.'
        },
        {
            category: 'The Crown',
            question: 'The Crown’s first series covers Elizabeth’s younger years. From what year in history did The Crown series one cover?',
            answer: '1947 (beginning with Elizabeth’s marriage to Philip).'
        },
        {
            category: 'The Crown',
            question: 'Name the actress who will play Margaret Thatcher in The Crown series four.',
            answer: 'Gillian Anderson.'
        },
        {
            category: 'The Crown',
            question: 'During series three, we see Prince Charles sent to Wales to learn Welsh. Name the Welsh castle where his 1969 investiture took place.',
            answer: 'Caernarfon Castle, Wales.'
        },
        {
            category: 'Friends',
            question: 'What is the full name of Brad Pitt’s character in ‘The One with the Rumor’?',
            answer: 'Will Colbert'
        },
        {
            category: 'Friends',
            question: 'What is the name of the waiter who compares Monica to an “empty vase”?',
            answer: 'Julio'
        },
        {
            category: 'Friends',
            question: 'Alessandro runs the Italian restaurant Alessandro’s – but which country is he actually from?',
            answer: 'Lebanon'
        },
        {
            category: 'Friends',
            question: 'What does Rachel claim is her favourite movie?',
            answer: 'Dangerous Liaisons'
        },
        {
            category: 'Friends',
            question: 'What is Rachel’s actual favourite movie?',
            answer: 'Weekend at Bernie’s'
        },
        {
            category: 'Friends',
            question: 'In ‘The One Where No-One’s Ready’, what does Ross offer to drink to prove how much Rachel means to him?',
            answer: 'A glass of fat'
        },
        {
            category: 'Friends',
            question: 'What does Ross send to Ugly Naked Guy in an attempt to convince him to sell him his apartment?',
            answer: 'A basket of mini-muffins'
        },
        {
            category: 'Friends',
            question: 'How was Joey’s character Dr Drake Ramoray originally killed off on Days of our Lives?',
            answer: 'Fell down an elevator shaft'
        },
        {
            category: 'Friends',
            question: 'How many pages was Rachel’s letter to Ross about the reasons for their break-up?',
            answer: '18 pages (front and back)'
        },
        {
            category: 'Friends',
            question: 'What does Chandler end up giving Kathy for her birthday, after Joey gives her Chandler’s original gift The Velveteen Rabbit?',
            answer: 'A pen (that’s also a clock)'
        },
        {
            category: 'Friends',
            question: 'Which Friend has the middle name Muriel?',
            answer: 'Chandler'
        },
        {
            category: 'Friends',
            question: 'What song does Chandler sing on the videotape recording that cuts in after Joey’s faked Law & Order appearance?',
            answer: 'David Bowie’s ‘Space Oddity’'
        },
        {
            category: 'Friends',
            question: 'In ‘The One With All The Cheesecakes’,Rachel and Chandler steal their neighbour’s cheesecakes – where are the cheesecakes delivered from?',
            answer: 'Mama’s Little Bakery (Chicago, Illinois)'
        },
        {
            category: 'Friends',
            question: 'Ross’s model of Apollo 8 has “Captain Ross” written on the side – but what is written on the bottom?',
            answer: '“I hate Monica”'
        },
        {
            category: 'Friends',
            question: 'In the first episode of Friends, how does Monica get back at Paul the wine guy after he lies to her about his sex life?',
            answer: 'She breaks his watch'
        },
        {
            category: 'Friends',
            question: 'Name the six things Joey claims rhyme with “Rachel” in ‘The One With The Girl From Poughkeepsie’ – you win a point for each!',
            answer: 'Bagel, mail, jail, bail, cable, maple'
        },
        {
            category: 'Friends',
            question: 'Why does Phoebe break up with her cop boyfriend Gary shortly after they move in together?',
            answer: 'He shoots a bird'
        },
        {
            category: 'Friends',
            question: 'What is the name of Ross’s love rival played by Greg Kinnear in season 10’s ‘The One With Ross’ Grant’?',
            answer: 'Benjamin Hobart'
        },
        {
            category: 'Friends',
            question: 'What short message does Gavin (Dermot Mulroney) write on his birthday gift to Rachel in ‘The One with Phoebe’s Rats’?',
            answer: '“From Gavin”'
        },
        {
            category: 'Friends',
            question: 'Which of Rachel’s ex-boyfriends owns the same sweater as Ross, causing some confusion as to the identity of the father of Rachel’s baby?',
            answer: 'Tag'
        },
        {
            category: 'Stranger Things',
            question: 'In the first season of Stranger Things, one character became a viral sensation despite vanishing early on in the run. What was her name?',
            answer: 'Barb'
        },
        {
            category: 'Stranger Things',
            question: 'In season 2, which former Lord of the Rings star joined the cast as Joyce Byers new love interest?',
            answer: 'Sean Astin'
        },
        {
            category: 'Stranger Things',
            question: 'Stranger Things takes place in a fictional rural town called Hawkins – but what US state is it in?',
            answer: 'Indiana'
        },
        {
            category: 'Stranger Things',
            question: 'What are the first names of the Duffer brothers, who created the show?',
            answer: 'Matt and Ross'
        },
        {
            category: 'Stranger Things',
            question: 'What game are the boys in Stranger Things frequently shown to enjoy playing – including at the very beginning of the show?',
            answer: 'Dungeons and Dragons'
        },
        {
            category: 'Stranger Things',
            question: 'At the start of the second season, the boys go dressed up as characters from what popular film for Halloween?',
            answer: 'Ghostbusters'
        },
        {
            category: 'Stranger Things',
            question: 'What is the name of Dustin’s girlfriend whom he meets at Science Camp between seasons 2 and 3?',
            answer: 'Suzie'
        },
        {
            category: 'Stranger Things',
            question: 'What is the name of the ice cream parlour at which Steve works in season 3?',
            answer: 'Scoops Ahoy!'
        },
        {
            category: 'Stranger Things',
            question: 'In season 1 what unusual item does Joyce use as a method of communication to try and get into contact with her son?',
            answer: 'Christmas Lights'
        },
        {
            category: 'Stranger Things',
            question: 'What is the name of the private investigator played by Fleabag star Brett Gelman in seasons 2 and 3?',
            answer: 'Murray Baumann'
        },
        {
            category: 'Stranger Things',
            question: 'What song by the Clash plays a prominent part in season 1?',
            answer: 'Should I Stay or Should I Go'
        },
        {
            category: 'Stranger Things',
            question: 'In what year does Stranger Things begin?',
            answer: 1983
        },
        {
            category: 'Stranger Things',
            question: 'What is the name of Lucas’ younger sister?',
            answer: 'Erica'
        },
        {
            category: 'Stranger Things',
            question: 'What foodstuff is Eleven a huge fan of – living off a stolen supply of them when she was stranded in the woods?',
            answer: 'Eggo Waffles'
        },
        {
            category: 'Stranger Things',
            question: 'What is the name of the malevolent entity that possesses Will in season 2?',
            answer: 'The Mind Flayer'
        },
        {
            category: 'Stranger Things',
            question: 'What name is given to the slingshot frequently used by Lucas?',
            answer: 'The Wrist Rocket'
        },
        {
            category: 'Stranger Things',
            question: 'What is the name given to the CIA experiments of which Eleven’s mother was a participant?',
            answer: 'Project MK Ultra'
        },
        {
            category: 'Stranger Things',
            question: 'What creative hobby does Jonathan pursue?',
            answer: 'Photography'
        },
        {
            category: 'Stranger Things',
            question: 'What arcade game is Max shown to be particularly good at?',
            answer: 'Dig Dug'
        },
        {
            category: 'Stranger Things',
            question: 'Finn Wolfhard, who plays Mike, went onto star an film adaptation of which popular ’80s-set novel?',
            answer: 'It'
        },
        {
            category: 'Star Wars',
            question: 'In the first ever Star Wars live action TV series, Tha Mandalorian, which actor plays the title character?',
            answer: 'Pedro Pascal'
        },
        {
            category: 'Star Wars',
            question: 'What is the name of the bounty hunter who captured Han Solo in The Empire Strikes Back',
            answer: 'Boba Fett'
        },
        {
            category: 'Star Wars',
            question: 'Which space opera comic strip originally inspired George Lucas to write the first Star Wars film?',
            answer: 'Flash Gordon'
        },
        {
            category: 'Star Wars',
            question: 'What colour was Qui-Gon Jinn’s lightsaber?',
            answer: 'Green'
        },
        {
            category: 'Star Wars',
            question: 'In total, how many series have there been of animated series Star Wars: The Clone Wars?',
            answer: 'Seven'
        },
        {
            category: 'Star Wars',
            question: 'Who was the director of 2016’s Rogue One: A Star Wars Story?',
            answer: 'Gareth Edwards'
        },
        {
            category: 'Star Wars',
            question: 'In The Empire Strikes Back, Lando Calrissian is the Baron Administrator of Cloud City on which planet?',
            answer: 'Bespin'
        },
        {
            category: 'Star Wars',
            question: 'What day of the year is known by fans as Star Wars Day?',
            answer: 'May 4th'
        },
        {
            category: 'Star Wars',
            question: 'What is the name of Jabba the Hutt’s in-house band in Return of the Jedi?',
            answer: 'The Max Rebo Band'
        },
        {
            category: 'Star Wars',
            question: 'Who played young Han Solo in the 2018 film Solo: A Star Wars Story?',
            answer: 'Alden Ehrenreich'
        },
        {
            category: 'Star Wars',
            question: 'Which Star Wars film has the most Academy Awards to its name?',
            answer: 'Star Wars: A New Hope (6 wins)'
        },
        {
            category: 'Star Wars',
            question: 'Jar Jar Binks belongs to what species?',
            answer: 'Gungan'
        },
        {
            category: 'Star Wars',
            question: 'Which actor was “digitally resurrected” to posthumously appear in Rogue One: A Star Wars Story?',
            answer: 'Peter Cushing'
        },
        {
            category: 'Star Wars',
            question: 'What is the name of the planet on which Luke Skywalker goes into hiding for several years?',
            answer: 'Ahch-To'
        },
        {
            category: 'Star Wars',
            question: 'Kendal Ozzel was the commanding officer of which ship?',
            answer: 'The Executor'
        },
        {
            category: 'Star Wars',
            question: 'In what year was the first installment of the Star Wars: Battlefront video game series released?',
            answer: 2004
        },
        {
            category: 'Star Wars',
            question: 'Adam Driver turned down a role in which other blockbuster to play Kylo Ren?',
            answer: 'Batman vs Superman'
        },
        {
            category: 'Star Wars',
            question: 'Can you name the Jedi Council member who is of the same species as Yoda?',
            answer: 'Yaddle'
        },
        {
            category: 'Star Wars',
            question: 'Which species believed that C-3PO might be “some sort of god”?',
            answer: 'Ewoks'
        },
        {
            category: 'Star Wars',
            question: 'Which acclaimed actress stars as Maz Kanata in the sequel trilogy?',
            answer: 'Lupita Nyong’o'
        },
        {
            category: 'The Simpsons',
            question: 'In what year did the first episode of The Simpsons air?',
            answer: 1989
        },
        {
            category: 'The Simpsons',
            question: 'What’s the name of the Simpsons’ pet dog?',
            answer: 'Santa’s Little Helper'
        },
        {
            category: 'The Simpsons',
            question: 'Which cartoon inspired The Itchy & Scratchy Show?',
            answer: 'Tom and Jerry'
        },
        {
            category: 'The Simpsons',
            question: 'What’s Marge’s natural hair colour?',
            answer: 'Grey'
        },
        {
            category: 'The Simpsons',
            question: 'Who composed The Simpsons’ theme tune?',
            answer: 'Danny Elfman'
        },
        {
            category: 'The Simpsons',
            question: 'What’s Homer’s job?',
            answer: 'Nuclear Safety Inspector'
        },
        {
            category: 'The Simpsons',
            question: 'How many cats have the Simpsons had?',
            answer: 'Five, though Snowball V is often referred to as the new and improved Snowball II'
        },
        {
            category: 'The Simpsons',
            question: 'Name the three types of Duff Beer that are served by the same pipe in season 4 episode 16 Duffless',
            answer: 'Duff, Duff Lite and Duff Dry'
        },
        {
            category: 'The Simpsons',
            question: 'What’s Marge’s maiden name?',
            answer: 'Himself'
        },
        {
            category: 'The Simpsons',
            question: 'Who did Matt Groening voice?',
            answer: 'Bouvier'
        },
        {
            category: 'The Simpsons',
            question: 'How many children to Apu and Manjula have?',
            answer: 'Eight'
        },
        {
            category: 'The Simpsons',
            question: 'What’s Mr Burns’ catchphrase?',
            answer: 'Excellent!'
        },
        {
            category: 'The Simpsons',
            question: 'Which Simpsons kid starred in the short Playdate with Destiny?',
            answer: 'Maggie Simpson'
        },
        {
            category: 'The Simpsons',
            question: 'What’s Bart’s full name?',
            answer: 'Bartholomew JoJo Simpson'
        },
        {
            category: 'The Simpsons',
            question: 'Which real-life dictator shares his surname with Milhouse’s mother?',
            answer: 'Benito Mussolini'
        },
        {
            category: 'The Simpsons',
            question: 'What’s the name of the retirement home where Homer’s father, Abraham Simpson, lives?',
            answer: 'Springfield Retirement Castle'
        },
        {
            category: 'The Simpsons',
            question: 'Which member of the Simpsons family is a vegetarian?',
            answer: 'Lisa Simpson'
        },
        {
            category: 'The Simpsons',
            question: 'What are Marge’s twin sisters called?',
            answer: 'Patty and Selma'
        },
        {
            category: 'The Simpsons',
            question: 'How many times has Krusty the Clown been married?',
            answer: 'Fifteen'
        },
        {
            category: 'The Simpsons',
            question: 'Which Springfield resident often appears as the Devil?',
            answer: 'Ned Flanders'
        },
        {
            category: 'Game of Thrones',
            question: 'The drummer for which famous band made a cameo appearance during the Red Wedding?',
            answer: 'Coldplay'
        },
        {
            category: 'Game of Thrones',
            question: 'How many episodes of Game of Thrones are there in total?',
            answer: 73
        },
        {
            category: 'Game of Thrones',
            question: 'Which character appears in more episodes than any other? (67)',
            answer: 'Tyrion Lannister (Peter Dinklage)'
        },
        {
            category: 'Game of Thrones',
            question: 'What is the name of Jon Snow’s direwolf?',
            answer: 'Ghost'
        },
        {
            category: 'Game of Thrones',
            question: 'Who is king of Westeros at the very start of the show?',
            answer: 'Robert Baratheon'
        },
        {
            category: 'Game of Thrones',
            question: 'Which British actress was originally cast as Daenerys in the unaired pilot episode?',
            answer: 'Tamzin Merchant'
        },
        {
            category: 'Game of Thrones',
            question: '‘All men must die’ translates as what term in High Valyrian?',
            answer: 'Valar morghulis'
        },
        {
            category: 'Game of Thrones',
            question: 'Fill in the blanks for Emilia Clarke’s original title: “Daenerys Stormborn of the House Targaryen, First of Her Name, the Unburnt, Queen of the ____ and the ____, Khaleesi of the Great Grass Sea, ____ of Chains, and ____ of Dragons”',
            answer: 'Andals, First Men, Breaker, Mother'
        },
        {
            category: 'Game of Thrones',
            question: 'The largest skull in the dungeons beneath King’s Landing belonged to which dragon?',
            answer: 'Balerion (the Black Dread)'
        },
        {
            category: 'Game of Thrones',
            question: 'Which animal does Tywin Lannister skin during his first appearance in the show?',
            answer: 'Deer (buck or stag also acceptable)'
        },
        {
            category: 'Game of Thrones',
            question: 'Which actress plays the role of Margaery Tyrell?',
            answer: 'Natalie Dormer'
        },
        {
            category: 'Game of Thrones',
            question: 'Name the ancestral home of House Lannister.',
            answer: 'Casterly Rock'
        },
        {
            category: 'Game of Thrones',
            question: 'How does Viserys Targaryen die in season 1?',
            answer: 'Khal Drogo pours liquid gold over his head'
        },
        {
            category: 'Game of Thrones',
            question: 'Which character says the line: “Say it. Say her name. Say it!”',
            answer: 'Oberyn Martell'
        },
        {
            category: 'Game of Thrones',
            question: 'Name the orphan baker boy who befriends Arya Stark.',
            answer: 'Hot Pie'
        },
        {
            category: 'Game of Thrones',
            question: 'What do the initials stand for in the infamous – and ultimately correct – Game of Thrones fan theory ‘R+L=J’?',
            answer: 'Rhaegar, Lyanna, Jon'
        },
        {
            category: 'Game of Thrones',
            question: 'What is the name of the huge mercenary army commanded by Daenerys?',
            answer: 'The Unsullied'
        },
        {
            category: 'Game of Thrones',
            question: 'Which character is often referred to with ‘Giantsbane’ in their name?',
            answer: 'Tormund'
        },
        {
            category: 'Game of Thrones',
            question: 'Which vegetable-related nickname is Stannis Baratheon’s right-hand man Davos Seaworth known by?',
            answer: 'The Onion Knight'
        },
        {
            category: 'Game of Thrones',
            question: 'Which character ends up being crowned King of the Six Kingdoms in the final episode?',
            answer: 'Bran Stark (Bran the Broken)'
        },
        {
            category: 'Breaking Bad',
            question: 'How many episodes of Breaking Bad were aired?',
            answer: 62
        },
        {
            category: 'Breaking Bad',
            question: 'Which year was Breaking Bad first aired?',
            answer: 2008
        },
        {
            category: 'Breaking Bad',
            question: 'What is Gus Fring’s henchman Mike’s surname?',
            answer: 'Ehrmantraut'
        },
        {
            category: 'Breaking Bad',
            question: 'What does DEA stand for?',
            answer: 'Drug Enforcement Agency'
        },
        {
            category: 'Breaking Bad',
            question: 'Which area of science does Walt teach at school?',
            answer: 'Chemistry'
        },
        {
            category: 'Breaking Bad',
            question: 'The highest-rated episode on IMDB – scoring a perfect 10/10 with over 110,000 votes – shares its name with the work of famous poet Percy Shelley. Name it.',
            answer: 'Ozymandias'
        },
        {
            category: 'Breaking Bad',
            question: 'Name the plant Walt used to poison a child.',
            answer: 'Lily of the valley'
        },
        {
            category: 'Breaking Bad',
            question: 'Which food does Walt infamously throw onto the roof of his house?',
            answer: 'Pizza'
        },
        {
            category: 'Breaking Bad',
            question: 'Name the famous author of the book Hank reads to discover Walt’s true identity.',
            answer: 'Walt Whitman'
        },
        {
            category: 'Breaking Bad',
            question: 'What is the name of Skinny Pete’s accomplice?',
            answer: 'Badger'
        },
        {
            category: 'Breaking Bad',
            question: 'Gus Fring’s meth Superlab is based underneath a warehouse. What does the above-ground business specialise in?',
            answer: 'Laundry'
        },
        {
            category: 'Breaking Bad',
            question: 'Which Star Wars director directed arguably the most polarising episode in the entire series ‘Fly’?',
            answer: 'Rian Johnson'
        },
        {
            category: 'Breaking Bad',
            question: 'What nickname does Jesse give to the RV he uses with Walt to cook meth?',
            answer: 'The Krystal Ship'
        },
        {
            category: 'Breaking Bad',
            question: 'What is Gus Fring’s nationality?',
            answer: 'Chilean'
        },
        {
            category: 'Breaking Bad',
            question: 'Name the actor who plays Walt Jr.',
            answer: 'RJ Mitte'
        },
        {
            category: 'Breaking Bad',
            question: 'Two elements from the periodic table – Br and Ba – are used in the Breaking Bad title image. Name the elements.',
            answer: 'Bromine, Barium'
        },
        {
            category: 'Breaking Bad',
            question: 'Which colour is commonly associated with Marie Schrader throughout the show?',
            answer: 'Purple'
        },
        {
            category: 'Breaking Bad',
            question: 'Name the infant daughter of Walt and Skyler White.',
            answer: 'Holly'
        },
        {
            category: 'Breaking Bad',
            question: 'Lydia Rodarte-Quayle was the Head of Logistics at which fictional global conglomerate with ties to Gus Fring and used to ship Walt’s product across the world?',
            answer: 'Madrigal'
        },
        {
            category: 'Breaking Bad',
            question: 'Complete the iconic Walt phrase: “You clearly don’t know who you’re talking to, so let me clue you in. I am not in danger, Skyler. I am the danger! A guy opens his door and gets shot and you think that of me? No. I am ____!”',
            answer: 'The one who knocks'
        },
        {
            category: 'Action movie',
            question: 'What’s the name of the building where Die Hard’s events take place?',
            answer: 'Nakatomi Plaza'
        },
        {
            category: 'Action movie',
            question: 'Who played baddie Howard Payne in Speed?',
            answer: 'Dennis Hopper'
        },
        {
            category: 'Action movie',
            question: 'What movie is the line “I will look for you, I will find you, and I will kill you” from?',
            answer: 'Taken'
        },
        {
            category: 'Action movie',
            question: 'Where does John Wick check-in after retrieving his weapons and gold coins from under his floor in the first John Wick?',
            answer: 'The Continental Hotel'
        },
        {
            category: 'Action movie',
            question: 'What actor feels the need, the need for speed, in Top Gun?',
            answer: 'Tom Cruise'
        },
        {
            category: 'Action movie',
            question: 'How many movies make up the Fast & Furious franchise so far?',
            answer: 'Nine'
        },
        {
            category: 'Action movie',
            question: 'What’s the name of the last completed film Bruce Lee starred in?',
            answer: 'Enter the Dragon'
        },
        {
            category: 'Action movie',
            question: 'Who performed the theme song for the upcoming James Bond movie No Time to Die?',
            answer: 'Billie Eilish'
        },
        {
            category: 'Action movie',
            question: 'How many members were in Kill Bill’s Deadly Viper Assassination Squad?',
            answer: 'Six'
        },
        {
            category: 'Action movie',
            question: 'What was Jason Bourne’s real name?',
            answer: 'David Webb'
        },
        {
            category: 'Action movie',
            question: 'When was the first Rambo movie released?',
            answer: 1982
        },
        {
            category: 'Action movie',
            question: 'Who stars in Atomic Blonde?',
            answer: 'Charlize Theron'
        },
        {
            category: 'Action movie',
            question: 'What does Commando’s Colonel John Matrix eat for breakfast?',
            answer: 'Green Berets'
        },
        {
            category: 'Action movie',
            question: 'How many rules does Jason Statham’s transporter have in The Transporter?',
            answer: 'Four: “The deal is the deal”, “No names”, “Never open the package” and “Never make a promise you can’t keep”'
        },
        {
            category: 'Action movie',
            question: 'What’s the main character, played by Ryan Gosling, in Drive called?',
            answer: 'His name is never said'
        },
        {
            category: 'Action movie',
            question: 'What crime was Harrison Ford’s character in The Fugitive wrongly accused of?',
            answer: 'Murdering his wife'
        },
        {
            category: 'Action movie',
            question: 'How many Mission Impossible films has Simon Pegg been in?',
            answer: 'Four'
        },
        {
            category: 'Action movie',
            question: 'Who directed the 2010 remake of True Grit?',
            answer: 'The Coen brothers'
        },
        {
            category: 'Action movie',
            question: 'Which James Bond film marked Judi Dench’s first appearance as M?',
            answer: 'Golden Eye'
        },
        {
            category: 'Action movie',
            question: 'In what movie does Keanu Reeves infiltrate a group of surfers involved in armed robberies?',
            answer: 'Point Break'
        },
        {
            category: 'EastEnders',
            question: 'In what year was the first episode of EastEnders broadcast?',
            answer: 1985
        },
        {
            category: 'EastEnders',
            question: 'Who delivered the immortal line, ‘You can’t tell me what to do, you ain’t my mother!’?',
            answer: 'Zoe Slater'
        },
        {
            category: 'EastEnders',
            question: 'Who reached number 4 in the UK charts with a song set to the EastEnders theme tune, called Anyone Can Fall in Love?',
            answer: 'Anita Dobson'
        },
        {
            category: 'EastEnders',
            question: 'Who is officially the longest serving character on the Square?',
            answer: 'Ian Beale'
        },
        {
            category: 'EastEnders',
            question: 'What item did Steve Owen use to kill Saskia?',
            answer: 'Ashtray'
        },
        {
            category: 'EastEnders',
            question: 'Patsy Palmer and Sid Owen played which famous Walford lovebirds?',
            answer: 'Bianca and Ricky'
        },
        {
            category: 'EastEnders',
            question: 'What was the name of Robbie Jackson’s dog?',
            answer: 'Wellard'
        },
        {
            category: 'EastEnders',
            question: 'Pat Butcher was famous for her love of which item of jewellery?',
            answer: 'Earrings'
        },
        {
            category: 'EastEnders',
            question: 'What unwanted present did Den give to Angie for the Christmas of 1986?',
            answer: 'Divorce papers'
        },
        {
            category: 'EastEnders',
            question: 'Zainab, Tamwar and Syed were all members of which family?',
            answer: 'The Masoods'
        },
        {
            category: 'EastEnders',
            question: 'Pauline Fowler famously hit her husband with a frying pan. What was his name?',
            answer: 'Arthur'
        },
        {
            category: 'EastEnders',
            question: 'What was Queen Vic landlady Peggy Mitchell’s famous catchphrase?',
            answer: '‘Get outta my pub!’'
        },
        {
            category: 'EastEnders',
            question: 'Which iconic, chain-smoking EastEnders character was often found working in the launderette?',
            answer: 'Dot Cotton'
        },
        {
            category: 'EastEnders',
            question: 'Which actor is still sometimes referred to as ‘Barry off EastEnders’?',
            answer: 'Shaun Williamson'
        },
        {
            category: 'EastEnders',
            question: 'If you live in Albert Square, what is your postcode?',
            answer: 'E20'
        },
        {
            category: 'EastEnders',
            question: 'Phil and Grant Mitchell have a sister, what is her name?',
            answer: 'Sam'
        },
        {
            category: 'EastEnders',
            question: 'Sonia famously played which musical instrument as a child?',
            answer: 'Trumpet'
        },
        {
            category: 'EastEnders',
            question: 'Who was Max Branning revealed to be having an affair with on Christmas Day 2007?',
            answer: 'Stacey'
        },
        {
            category: 'EastEnders',
            question: 'Which singer played Tiffany Mitchell?',
            answer: 'Martine McCutcheon'
        },
        {
            category: 'EastEnders',
            question: 'Gavin and Stacey star Larry Lamb played which EastEnders villain?',
            answer: 'Archie Mitchell'
        },
        {
            category: 'Royal Family',
            question: 'How many great-grandchildren does the Queen have?',
            answer: 'Eight'
        },
        {
            category: 'Royal Family',
            question: 'What country was Prince Philip born in?',
            answer: 'Greece'
        },
        {
            category: 'Royal Family',
            question: 'What year did the Queen famously describe as an “annus horribilis”?',
            answer: 1992
        },
        {
            category: 'Royal Family',
            question: 'How old was the Queen at the time of her coronation?',
            answer: 27
        },
        {
            category: 'Royal Family',
            question: 'True or false: the Queen was in Kenya when she learnt of the death of her father King George VI',
            answer: 'True'
        },
        {
            category: 'Royal Family',
            question: 'What did the Queen work as while enlisted in the military during World War Two?',
            answer: 'Mechanic'
        },
        {
            category: 'Royal Family',
            question: 'The Queen’s official birthday is in June – but when is her actual birthday?',
            answer: '21 April'
        },
        {
            category: 'Royal Family',
            question: 'Princess Anne and Zara Tindall have both competed in the Olympics in which sport?',
            answer: 'Equestrian'
        },
        {
            category: 'Royal Family',
            question: 'Zara’s husband Mike Tindall played which sport professionally?',
            answer: 'Rugby'
        },
        {
            category: 'Royal Family',
            question: 'What breed of dog is the Queen famously a fan of?',
            answer: 'Corgis'
        },
        {
            category: 'Royal Family',
            question: 'Including staff bedrooms, bedrooms are there in Buckingham Palace?',
            answer: 240
        },
        {
            category: 'Royal Family',
            question: 'How old was the Queen when she met Prince Philip?',
            answer: 13
        },
        {
            category: 'Royal Family',
            question: 'What flower do all royal brides have in their bouquet?',
            answer: 'Myrtle'
        },
        {
            category: 'Royal Family',
            question: 'What are Charles and Camilla’s nicknames for each other?',
            answer: 'Fred and Gladys'
        },
        {
            category: 'Royal Family',
            question: 'At what university did Prince William meet Kate Middleton?',
            answer: 'St. Andrews'
        },
        {
            category: 'Royal Family',
            question: 'At what castle did the investiture of Prince Charles take place?',
            answer: 'Caernarfon Castle'
        },
        {
            category: 'Royal Family',
            question: 'What is the name of the Queen’s Norfolk residence?',
            answer: 'Sandringham'
        },
        {
            category: 'Royal Family',
            question: 'The 2011 amendment meaning male descendants no longer take priority over females was signed in which Australian city?',
            answer: 'Perth'
        },
        {
            category: 'Royal Family',
            question: 'Who is the Queen’s youngest son?',
            answer: 'Prince Edward'
        },
        {
            category: 'Royal Family',
            question: 'Where was Prince Harry deployed to during his two tours in the army?',
            answer: 'Afghanistan'
        },
        {
            category: 'Politics',
            question: 'Which state was George W Bush governor of before becoming President of the United States?',
            answer: 'Texas'
        },
        {
            category: 'Politics',
            question: 'Which former British Prime Minister graduated from Oxford University in 1947 with Second-Class Honours in Chemistry?',
            answer: 'Margaret Thatcher'
        },
        {
            category: 'Politics',
            question: 'How long does Prime Minister’s Questions last?',
            answer: 'Thirty Minutes'
        },
        {
            category: 'Politics',
            question: 'A member of which band threw a bucket of water over John Prescott at the 1998 Brit Awards?',
            answer: 'Chumbawamba'
        },
        {
            category: 'Politics',
            question: 'What did Theresa May claim was the naughtiest thing she had done as a child in a 2017 interview?',
            answer: 'Ran through fields of wheat'
        },
        {
            category: 'Politics',
            question: 'Which politician described Russia as “a riddle, wrapped in a mystery, inside an enigma”?',
            answer: 'Winston Churchill'
        },
        {
            category: 'Politics',
            question: 'What is the name of the gallery for members of the public to watch The House of Commons?',
            answer: 'Strangers’ Gallery'
        },
        {
            category: 'Politics',
            question: 'What is the official name of Big Ben?',
            answer: 'Elizabeth Tower'
        },
        {
            category: 'Politics',
            question: 'What animal is the symbol of the US Democratic Party?',
            answer: 'Donkey'
        },
        {
            category: 'Politics',
            question: 'Who is the current King of Jordan?',
            answer: 'Abdullah II'
        },
        {
            category: 'Politics',
            question: 'Where did Richard Nixon hold his famous “I’m Not a Crook” press conference?',
            answer: 'The Contemporary Resort at Disney World, Florida'
        },
        {
            category: 'Politics',
            question: 'Who was the only British Prime Minister to be assassinated?',
            answer: 'Spencer Perceval'
        },
        {
            category: 'Politics',
            question: 'Which US President was diagnosed with polio after becoming paralysed from the waist down?',
            answer: 'Franklin D. Roosevelt'
        },
        {
            category: 'Politics',
            question: 'What were French Fries renamed to in several US Congressional cafeterias after France refused to support the Iraq War?',
            answer: 'Freedom Fries'
        },
        {
            category: 'Politics',
            question: 'Former leader of Change UK Anna Soubry used to be a presenter on which long-running daytime show?',
            answer: 'This Morning'
        },
        {
            category: 'Politics',
            question: 'True or false: in 2002 the President of Turkmenistan renamed the month of January after himself',
            answer: 'True'
        },
        {
            category: 'Politics',
            question: 'During the State Opening of Parliament, the doors of the House of Lords are shut in front of Black Rod – what historical event does this ritual date back to?',
            answer: 'English Civil War'
        },
        {
            category: 'Politics',
            question: '“If you let a bully come into your front yard, the next day he’ll be on your porch” is a quote from which US President?',
            answer: 'Lyndon B. Johnson'
        },
        {
            category: 'Politics',
            question: 'How long is a French President elected for?',
            answer: 'Five Years'
        },
        {
            category: 'Politics',
            question: 'Which Conservative politician famously fed British beef to his daughter, in a press event to prove its safety from B.S.E?',
            answer: 'John Gummer'
        }
        ];

        return { questions: questions };
    }
}
