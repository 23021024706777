import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { QuestionComponent } from './question/question.component';
import { ReactiveFormsModule, FormBuilder } from '@angular/forms';
import { AddQuestionComponent } from './add-question/add-question.component';
import { LocationStrategy, HashLocationStrategy} from '@angular/common';
import { HttpClientInMemoryWebApiModule } from 'angular-in-memory-web-api';
import { FakeBackendService } from './fake-backend.service';
import { AppService } from './app.service';
import { HttpClientModule } from '@angular/common/http';


@NgModule({
  declarations: [
    AppComponent,
    QuestionComponent,
    AddQuestionComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    ReactiveFormsModule,
    HttpClientModule,
    HttpClientInMemoryWebApiModule.forRoot(FakeBackendService)
  ],
  providers: [
    FormBuilder,
    { 
      provide: LocationStrategy, 
      useClass: HashLocationStrategy 
    },
    AppService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
