import { Component } from '@angular/core';
import { Question } from '../models/question.model';
import { AppService } from '../app.service';

@Component({
  selector: 'app-question',
  templateUrl: './question.component.html',
  styleUrls: ['./question.component.scss']
})
export class QuestionComponent {
  title = 'Daily Question';

  public items: Question[] = [];
  public answerVisible: boolean;

  public question: Question;

  constructor(appService: AppService) {
    this.question = new Question();
    appService.getQuestions().subscribe((response: Question[]) => {
      this.items = response;

      if (this.items.length > 0) {
        this.setQuestion();
      }
    });
    
    this.answerVisible = false;
  }

  showAnswer() {
    this.answerVisible = true;
  }

  setQuestion() {
    this.question = this.items[Math.floor(Math.random() * this.items.length)];
  };

  anotherQuestionPlease() {
    this.setQuestion();
    this.answerVisible = false;
  }
}
