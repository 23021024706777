import { Component, OnInit } from '@angular/core';
import { FormBuilder, Form, FormGroup } from '@angular/forms';
import { AngularFirestore } from 'angularfire2/firestore';

@Component({
  selector: 'app-add-question',
  templateUrl: './add-question.component.html',
  styleUrls: ['./add-question.component.scss']
})
export class AddQuestionComponent implements OnInit {
  addQuestionForm: FormGroup;

  constructor(
    private db: AngularFirestore,
    private formBuilder: FormBuilder
  ) { 
    this.createAddQuestionForm();
  }

  ngOnInit() {
  }

  createAddQuestionForm() {
    this.addQuestionForm = this.formBuilder.group({
      category: '',
      question: '',
      answer: ''
    });
  }

  onSubmit(value: any) {
    this.db.collection('/items').add(value);
    this.createAddQuestionForm();
  }

}
